import BaseLink from './base'
import { requireAtLeastOne } from '../../util/props'
import { useCategory } from 'store/category'

const href = '/[category]'
const analyticsCategory = 'category'

export const CategoryLink = ({ id, slug = null, ...props }) => {
  let as = '',
    valid = true,
    title = slug || id
  if (!id && !!slug) {
    as = `/${slug}`
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const category = useCategory(id)
    valid = !!category.id
    as = valid && `/${category.slug}`
    title = valid && (category.name || id)
  }
  return BaseLink({
    analyticsCategory,
    valid,
    href,
    as,
    title,
    ...props,
  })
}
CategoryLink.propTypes = {
  id: requireAtLeastOne('id', 'slug'),
  slug: requireAtLeastOne('id', 'slug'),
}

export default CategoryLink
