import React from 'react'
import { ArticleLink, AuthorLink, TopicLink } from '../../../link'
import { YCDate } from 'util/datetime'
import Typography from '../../../../components/typography'
import LinkArrow from 'components/link-arrow'
import style from './style'
import { useArticleExt } from 'store/article'
import { defaultPropTypes } from './default'

const panelClasses = style.combine('col-12', 'news-panel', 'card')

export const NewsLayout = ({ id, className = panelClasses, layout }) => {
  const { title, description, publicationDate, author, topics = [] } = useArticleExt(id)

  return (
    <div className={style.combine(className, `${layout}-panel`, 'news-listing')}>
      <div className={style('card')}>
        <ArticleLink id={id}>
          <Typography variant="h3">{title}</Typography>
        </ArticleLink>
        <div className={style('panel-info')}>
          <ul className={style('panel-details')}>
            {publicationDate && (
              <li>
                <YCDate date={publicationDate} />
                {/* <p>{publicationDate}</p> */}
              </li>
            )}
            {author && (
              <li>
                By: <AuthorLink id={author.id} />
              </li>
            )}
          </ul>
          <ul className={style('panel-topics')}>
            {topics.map(({ id }) => (
              <li key={id}>
                <TopicLink id={id} key={id} />
              </li>
            ))}
          </ul>
        </div>
        <div className={style('panel-content')}>
          <p>{description}</p>
        </div>
        {/* <ArticleLink id={id}>
          <LinkArrow>Read more</LinkArrow>
        </ArticleLink> */}
      </div>
    </div>
  )
}
NewsLayout.propTypes = defaultPropTypes
NewsLayout.page_size = 50
NewsLayout.sort = 'publication_date'
NewsLayout.dateFilter = true

export default NewsLayout
