import React from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import { withDispatch } from 'store'
import { useOverlay } from 'store/overlay'

export const SearchLink = ({ page = 1, children, fetchSearchPage, fetchMissing, ...props }) => {
  const { data: { query = '', category = null } = {} } = useOverlay('search')

  const doSearch = async () => {
    NProgress.start()
    const [res, items] = await fetchSearchPage({ query, category, page }) // eslint-disable-line no-unused-vars
    NProgress.inc()
    await fetchMissing(items)
    NProgress.done()
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        doSearch()
        e.preventDefault()
      }}
      {...props}
    >
      {children || 'Search'}
    </a>
  )
}
SearchLink.propTypes = {
  page: PropTypes.number.isRequired,
  children: PropTypes.node,
  fetchSearchPage: PropTypes.func,
  fetchMissing: PropTypes.func,
}

export default withDispatch({
  search: ['fetchSearchPage'],
  shared: ['fetchMissing'],
})(SearchLink)
