import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'

import { api, actionBinder, reducerFromActions } from 'util/api'

import * as actions from './actions/author'
import * as propTypes from './types/author'
import { hookHelper } from 'util/store'
import { authorState, passArgs, stripObjPageData, matchSlug, byId, uniqueList } from './common'
import { Dispatch } from 'redux'
import AuthorType from './types/ts/author'
import ArticleType from './types/ts/article'
const bindActions = actionBinder(actions)
export { propTypes }

export const fetchAuthorsChunked =
  ({ ...chunkedParams }, { ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<AuthorType>(
      '/authors/',
      { ...chunkedParams },
      {
        dispatchPre: actions.preFetch,
        dispatchPost: actions.postFetch,
        dispatchFail: actions.failFetch,
        dispatch,
        ...params,
      }
    )
  }

export const fetchAuthorsById = (authors: string[], { ...params } = {}) =>
  fetchAuthorsChunked(
    {
      search: authors,
    },
    {
      method: 'POST',
      ...params,
    }
  )
export const fetchAuthorsBySlug = (
  authors: string[],
  { params: initialParams = {}, ...params } = {}
) => fetchAuthorsById(authors, { ...params, params: { ...initialParams, field: 'slug' } })

interface fetchAuthorArticlePageProps extends Record<string, any> {
  author: string
  sort?: string
  order?: string
  page?: number
  page_size?: number
}

export const fetchAuthorArticlePage =
  ({ author, sort, order, page = 1, page_size = 25, ...params }: fetchAuthorArticlePageProps) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleType>(`/authors/${author}/articles/`, {
      dispatchPre: actions.preFetchAuthorArticlePage,
      dispatchPost: actions.postFetchAuthorArticlePage,
      dispatchFail: actions.failFetchAuthorArticlePage,
      dispatch,
      ...params,
      params: {
        ...(params.params || {}),
        sort,
        order,
        page,
        page_size,
      },
      target: { author, page },
    })
  }

interface fetchAuthorArticlesProps {
  author: string
  articles: string[]
}

export const fetchAuthorArticlesById =
  ({ author, articles }: fetchAuthorArticlesProps, { ...params } = {}) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<ArticleType>(
      `/authors/${author}/articles/`,
      {
        search: articles,
      },
      {
        dispatchPre: actions.preFetchAuthorArticlePage,
        dispatchPost: actions.postFetchAuthorArticlePage,
        dispatchFail: actions.failFetchAuthorArticlePage,
        dispatch,
        method: 'POST',
        ...params,
        target: { author },
      }
    )
  }
export const fetchAuthorArticlesBySlug = (
  { author, articles }: fetchAuthorArticlesProps,
  { body = {}, ...params } = {}
) => fetchAuthorArticlesById({ author, articles }, { ...params, body: { ...body, field: 'slug' } })

export const fetchMissingAuthors =
  (authors: string[]) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { author } = getState()
    const authorsToFetch = uniqueList([...authors].filter((key) => !(key in author)))
    if (authorsToFetch.length < 1) return
    return await dispatch(fetchAuthorsById(authorsToFetch))
  }

export const [useAuthor, getAuthor] = hookHelper<AuthorType, [id: string]>(
  createCachedSelector([authorState, passArgs], byId)(passArgs)
)
export const [useAuthors, getAuthors] = hookHelper<Record<string, AuthorType>, never[]>(
  createSelector(authorState, (author) => stripObjPageData(author))
)
export const [useAuthorBySlug, getAuthorBySlug] = hookHelper<
  AuthorType | undefined,
  [slug: string]
>(createCachedSelector([authorState, passArgs], matchSlug)(passArgs))

const initialState = {}

export const mapDispatchToProps = {
  fetchAuthorsById,
  fetchAuthorsBySlug,
  fetchAuthorsChunked,
  fetchAuthorArticlesById,
  fetchAuthorArticlesBySlug,
  fetchAuthorArticlePage,
  fetchMissingAuthors,
}

export const reducer = reducerFromActions(actions, initialState)
