import getConfig from 'next/config'

import fetchTimeout from 'util/fetch'
import createLogger from 'util/log'

const adsLogger = createLogger('AdGlare')

const {
  publicRuntimeConfig: {
    paths: { adGlareUrl },
  },
} = getConfig()

export const Ads = {
  getAds: async () => {
    adsLogger.log(`Fetching AdGlare information: ${adGlareUrl}`)
    try {
      const res = await fetchTimeout(adGlareUrl)
      return await res.json()
    } catch {
      adsLogger.error(`Error fetching ad information.`)
      return null
    }
  },
}
