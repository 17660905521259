import { HYDRATE } from 'next-redux-wrapper'
import { DefaultRootState, useSelector } from 'react-redux'
import { Store, Reducer } from 'redux'

/**
 *
 * @param {function} reducer The reducer function that processes all actions
 * @param {Reducer} hydrator An optional function that processes the HYDRATE action. Defaults to a standard merge.
 */
export const reduceHelper =
  (reducer: Reducer, hydrator?: Reducer): Reducer =>
  (state, action) => {
    switch (action.type) {
      case HYDRATE:
        if (hydrator) return hydrator(state, action.payload)
        else return { ...state, ...action.payload }
      // case 'API_START_GET':
      // case 'API_END_GET':
      // case 'API_START_POST':
      // case 'API_END_POST':
      //   return reducer(state, action)
      default:
        return reducer(state, action)
    }
  }

// Simple type to retrieve the current state as minimum requirement for GetHookHelper
type HasGetState = Pick<Store, 'getState'>

// The return type of the useHook
type UseHookHelper<T = any, A extends Array<any> = any[]> = (...args: A) => T
// The return type of the getHook
type GetHookHelper<T = any, A extends Array<any> = any[], S extends HasGetState = HasGetState> = (
  { getState }: S,
  ...args: A
) => T
// The signature of hookHelper
type HookHelper<T = any, A extends Array<any> = any[], S extends HasGetState = HasGetState> = [
  UseHookHelper<T, A>,
  GetHookHelper<T, A, S>
]
// A helper selector type
type Selector<R = any, A extends Array<any> = any[]> = (state: any, ...args: A) => R

/**
 * Creates a useHook and a getHook, the former usable in functional components,
 * the other usable in class components. The latter requires a state object to be
 * passed to access the current state, the former does this via another hook.
 *
 * @param selector The selector to apply when fetching the data
 * @returns The data from the state as processed by @see selector
 */
export const hookHelper = <
  T = any, // The return type
  A extends Array<any> = any[], // The argument type
  S extends HasGetState = HasGetState // The state type (probably doesn't setting)
>(
  selector: Selector<T, A>
): HookHelper<T, A, S> => {
  const useHelper: UseHookHelper<T, A> = (...props: A[]) =>
    useSelector<DefaultRootState, T>((state) => selector(state, ...(props as A)))
  const getHelper: GetHookHelper<T, A, S> = ({ getState }: S, ...props: any[]): T =>
    selector(getState(), ...(props as A))
  return [useHelper, getHelper]
}

/**
 *
 * @param {Object} mapping The mapping to extract from.
 * @param {string} valueName The key to extract from each mapping item.
 */
export const mappingProps = (mapping: Record<string, Record<string, any>>, valueName: string) =>
  Object.entries(mapping).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [key]: val[valueName],
    }),
    {}
  )
