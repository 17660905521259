import NewsLayout from './news-listing'
import { defaultPropTypes } from './default'

import style from './style'

const panelClasses = style.combine('col-12', 'news-panel', 'card')

interface Props {
  className: any
}

export const NewsListingAuthor = ({ className, ...props }: Props) => (
  <NewsLayout className={style.extend(panelClasses, className)} {...props} />
)

NewsLayout.propTypes = defaultPropTypes
NewsLayout.page_size = 50
NewsLayout.sort = 'publication_date'

export default NewsListingAuthor
