import React from 'react'

import Typography from 'components/typography'
import { ArticleLink, TopicLink, CategoryLink } from '../../../link'
import { YCDate } from 'util/datetime'

import style from './style'
import { useArticleExt } from 'store/article'
import { defaultPropTypes } from './default'

const panelClasses = style.combine('col-12', 'news-panel')

export const ResultsLayout = ({ id, className = panelClasses, onItemClick, layout }) => {
  const { category, title, description, publicationDate, topics = [] } = useArticleExt(id)

  return (
    <div className={style.combine(className, `${layout}-panel`, 'news-listing')}>
      <div className={style('card', 'results')}>
        <div className={style('panel-head')}>
          <ArticleLink onClick={onItemClick} id={id} className={style('title')}>
            <Typography variant="h3">{title}</Typography>
          </ArticleLink>

          {category && (
            <CategoryLink
              id={category.id}
              onClick={onItemClick}
              className={style('panel-category')}
            />
          )}
          <div className={style('panel-topics')}>
            {topics &&
              topics.map(({ id }) => (
                <TopicLink
                  id={id}
                  key={id}
                  onClick={onItemClick}
                  className={style('badge', 'badge-pill', 'badge-secondary')}
                />
              ))}
          </div>
        </div>
        {publicationDate && (
          <div>
            <YCDate date={publicationDate} />
          </div>
        )}
        <Typography>{description}</Typography>
      </div>
    </div>
  )
}
ResultsLayout.propTypes = defaultPropTypes
ResultsLayout.page_size = 50

export default ResultsLayout
