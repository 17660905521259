import { actionReducer, actionListener, listToDict } from 'util/api'

// Helper functions
const withoutContent = ({ content, ...article }) => article // eslint-disable-line no-unused-vars

// When we receive a list of articles, apply to our global article index.
const fetchedArticles = (state, articles) => {
  return {
    ...state,
    articles: {
      ...state.articles,
      ...(articles ? listToDict(articles.map(withoutContent)) : {}),
    },
  }
}
// When we receive a list of content, apply to our global content index.
const fetchedContent = (state, articles) => ({
  ...fetchedArticles(state, articles),
  content: {
    ...state.content,
    ...(articles ? listToDict(articles) : {}),
  },
})
// When we receive a listing, returns the state and a list of article IDs
const fetchedListing = (state, articles) => [
  fetchedArticles(state, articles),
  articles ? articles.map((item) => item.id) : [],
]
const fetchedListingPage = (state, articles) => fetchedListing(state, articles)[0]

export const listenAuthorPage = actionListener(
  'AUTHOR_FETCH_ARTICLE_PAGE_END',
  (state, { items }) => fetchedListingPage(state, items)
)
export const listenCategoryPage = actionListener(
  'CATEGORY_FETCH_ARTICLE_PAGE_END',
  (state, { items }) => fetchedListingPage(state, items)
)
export const listenTopicPage = actionListener('TOPIC_FETCH_ARTICLE_PAGE_END', (state, { items }) =>
  fetchedListingPage(state, items)
)
export const listenSearchPage = actionListener(
  'SEARCH_FETCH_ARTICLE_PAGE_END',
  (state, { items }) => fetchedListingPage(state, items)
)
export const listenRelatedPage = actionListener(
  'RELATED_FETCH_ARTICLE_PAGE_END',
  (state, { items }) => fetchedListingPage(state, items)
)

export const preFetchSet = actionReducer('ARTICLE_FETCH_SET_START')
export const postFetchSet = actionReducer(
  'ARTICLE_FETCH_SET_DONE',
  ({ status }, articles) => ({ status, articles }),
  (state, { articles }) => fetchedArticles(state, articles)
)
export const failFetchSet = actionReducer('ARTICLE_FETCH_SET_FAIL')

export const preFetchContent = actionReducer('ARTICLE_FETCH_CONTENT_START')
export const postFetchContent = actionReducer(
  'ARTICLE_FETCH_CONTENT_DONE',
  ({ status }, article) => ({ status, article }),
  (state, { article }) => fetchedContent(state, [article])
)
export const failFetchContent = actionReducer('ARTICLE_FETCH_CONTENT_FAIL')
