import React from 'react'
import PropTypes from 'prop-types'

import NextApp from 'next/app'
import Router from 'next/router'
import getConfig from 'next/config'
const {
  publicRuntimeConfig: { siteTitleSuffix, siteName, defaultDescription, favicon, baseURL, gtmId },
} = getConfig()

import TagManager from 'react-gtm-module'

import Head from 'components/head'

import { wrapper } from 'store'
import { fetchMenuIfNeeded } from 'store/menu'
import { fetchCategoriesIfNeeded } from 'store/category'

import Layout from 'components/layout/base'
import ErrorPage from './_error'
import Error404 from './404'

import { AppWrapperContext } from 'context/AppWrapperContext'

import './_app.scss'
import NavigationProgress from 'util/progress'

import { logPageView } from 'util/analytics'
import { createLogger } from 'util/log'

const logger = createLogger('App')

class App extends NextApp {
  static propTypes = {
    pageProps: PropTypes.object,
    router: PropTypes.object,
    Component: PropTypes.any,
    ctx: PropTypes.object,
  }
  static async getInitialProps({ Component, ctx }) {
    const { dispatch } = ctx.store
    const { getInitialProps = null, delayInitialProps = true } = Component
    const { req = {}, res = {} } = ctx

    const { WrappedComponent = Component } = Component
    const { skipPreRender = false } = WrappedComponent
    let pageProps = {}
    let promises = []

    // Skip pre-fetching if we're pre-rendering a /500 page.
    // If this happens it is likely that certain parts of the system
    // might not be available (like the API). So it is best to avoid
    // hitting it.
    if (Object.keys(req.headers || {}).length != 0 || !skipPreRender) {
      // Await all our fetchers at once, so things can process in parallel.
      promises = await Promise.all([
        dispatch(fetchMenuIfNeeded()),
        dispatch(fetchCategoriesIfNeeded()),
        ...(getInitialProps && !delayInitialProps ? [getInitialProps(ctx)] : []),
      ])
    }

    // Fetch our pageProps if we needed some.
    if (getInitialProps && !delayInitialProps) {
      pageProps = { ...promises[promises.length - 1] }
    } else if (getInitialProps) {
      pageProps = await getInitialProps(ctx)
    }

    const { error, statusCode } = pageProps
    if (error) {
      logger.error(error)
    } else if (statusCode) {
      if (res) {
        res.statusCode = statusCode
      }
    }

    return {
      pageProps,
    }
  }
  componentDidMount() {
    Router.events.on('routeChangeComplete', logPageView)

    // Ensure that we enable smooth-scrolling on non-Firefox browsers
    const isFF = /firefox/i.test(navigator.userAgent)
    if (!isFF && !!document && !!document.documentElement) {
      document.documentElement.style.scrollBehavior = 'smooth'
    }

    // Adding Google Tag Manager script if env variable GTM_ID is set
    if (gtmId) {
      TagManager.initialize({
        gtmId,
      })
    }
  }
  render() {
    let { Component } = this.props
    const { pageProps, router } = this.props
    const { error, statusCode } = pageProps
    if (error) {
      Component = error === 404 || statusCode === 404 ? Error404 : ErrorPage
    }
    return (
      <>
        <Head
          htmlAttributes={{ lang: 'en' }}
          titleTemplate={`%s | ${siteTitleSuffix}`}
          defaultTitle={siteName}
        >
          <meta name="theme-color" content="#101112" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="description" content={defaultDescription} />
          <meta property="og:title" content={siteName} />
          <meta property="og:description" content={defaultDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={siteName} />
          <meta property="og:url" content={`${baseURL}${router.asPath}`} />
          <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />
          <link type="image/x-icon" rel="icon" href={favicon} />
          <link
            rel="search"
            type="application/opensearchdescription+xml"
            title={siteName}
            href="/opensearch.xml"
          />
        </Head>
        <NavigationProgress options={{ minimum: 0.25, speed: 200, trickleSpeed: 100 }} />
        <AppWrapperContext>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AppWrapperContext>
      </>
    )
  }
}

export default wrapper.withRedux(App)
