import { DefaultLayout, ListingLayout } from './layouts'
import { getCategory, useCategory } from 'store/category'
import { withDispatch } from 'store'
import { getPageData } from 'store/common'

export const CategoryListing = ({ id, ...props }) => {
  const category = useCategory(id)
  return ListingLayout({
    ...category,
    ...getPageData(category),
    DefaultLayout,
    type: 'category',
    id,
    ...props,
  })
}

CategoryListing.getInitialProps = async ({
  store,
  id: category,
  page = 1,
  fetchCategoryArticlePage,
  fetchCategoryStats,
  fetchMissing,
  year,
  month,
}) => {
  const { layout } = getCategory(store, category)

  return await ListingLayout.getInitialProps({
    fetch: fetchCategoryArticlePage,
    fetchMissing,
    fetchCategoryStats,
    DefaultLayout,
    page,
    layout,
    category,
    year,
    month,
  })
}

export default withDispatch({
  category: ['fetchCategoryArticlePage', 'fetchCategoryStats'],
  shared: ['fetchMissing'],
})(CategoryListing)
