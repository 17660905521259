import BaseLink from './base'
import { requireAtLeastOne } from '../../util/props'
import { useArticleExt } from 'store/article'

const href = '/[category]/[article]'
const analyticsCategory = 'article'

export const ArticleLink = ({ id = null, category = null, slug = null, ...props }) => {
  let as = '',
    valid = true,
    title = slug || id
  if (!id && !!slug && !!category) {
    as = `/${category}/${slug}`
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const article = useArticleExt(id)
    valid = !!article.id
    as = valid && `/${article.category.slug}/${article.slug}`
    title = valid && (article.title || id)
  }

  return BaseLink({
    analyticsCategory,
    valid,
    href,
    as,
    title,
    ...props,
  })
}
ArticleLink.propTypes = {
  id: requireAtLeastOne('id', 'slug'),
  slug: requireAtLeastOne('id', 'slug'),
  category: requireAtLeastOne('id', 'category'),
}

export default ArticleLink
