import ImageType, { ImageParamsType } from 'store/types/ts/image'
import { QueryParams } from 'util/api'
import { withQuery } from './api'

/**
 * Returns an image value from an API structure
 *
 * @param {Array<ImageType?>} images The image value as received from the API.
 * @param {string | undefined} defaultImage The default image URL to return if all `images` are null.
 * @param {Object} params Query params to pass if the image returned from the API is resizable.
 * @param {boolean} defaultResizable Whether the default image can be considered as resizable.
 * @returns The first image in the `images` array that is not null, or defaultImage.
 */
export const getImage = (
  [...images]: Array<ImageType | undefined | null>,
  defaultImage: string,
  params: ImageParamsType = {
    w: 1600,
    fm: 'jpg',
    fl: 'progressive',
    q: 80,
  },
  defaultResizable: boolean = false
) =>
  images.reverse().reduce(
    (val, img) => {
      const { url = null, resizable } = img || {}
      if (url) {
        return resizable ? withQuery(url, params as QueryParams) : url
      }
      return val
    },
    defaultResizable ? withQuery(defaultImage, params as QueryParams) : defaultImage
  )

export const getSocialShareImage = (
  [...images]: Array<ImageType | undefined | null>,
  defaultImage: string
) => {
  return getImage(images, defaultImage, {
    w: 1200,
    h: 630,
    fm: 'jpg',
    q: 75,
  })
}
