import React from 'react'
import PropTypes from 'prop-types'

import { RemoveScroll } from 'react-remove-scroll'

import KeyHandler, { KEYUP } from 'react-key-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

import StyleHelper from './style'
import moduleStyle from './overlay.module.scss'
import ContentContainer from '../components/container'
import { withDispatch, useActions } from 'store'
import { useOverlays, useOverlay } from 'store/overlay'

const style = StyleHelper(moduleStyle)

export const OverlayContainer = ({ children, hideAll }) => {
  const state = useOverlays()
  const visible = Object.values(state).some(({ visible, closing }) => visible || closing)

  const EventHandler = typeof window !== 'undefined' ? KeyHandler : React.Fragment
  const eventProps =
    typeof window !== 'undefined'
      ? {
          keyValue: 'Escape',
          keyEventName: KEYUP,
          onKeyHandle: () => hideAll(),
        }
      : {}

  return (
    <>
      <EventHandler {...eventProps} />
      {visible ? children : null}
    </>
  )
}
OverlayContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hideAll: PropTypes.func,
}
export default withDispatch({
  overlay: ['hideAll'],
})(OverlayContainer)

export const OverlayBase = ({ name, Component, ...props }) => {
  const overlayProps = useOverlay(name)
  const { closeOverlay } = useActions('overlay')
  const { visible, closing } = overlayProps
  const close = (...args) => closeOverlay(name, ...args)

  if (!visible) return <></>

  return (
    <RemoveScroll forwardProps>
      <div
        className={style('overlay-base', closing ? 'overlay-closing' : null)}
        {...props}
        // TODO: Look better into issues with Select lib and onclick
        // onClick={() => close()}
      >
        <div className={style('overlay-container')}>
          <a
            href="#"
            className={style('overlay-close')}
            onClick={(e) => {
              close()
              e.preventDefault()
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="times"
              className="svg-inline--fa fa-times fa-w-10 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              alt="times"
            >
              <path
                fill="currentColor"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
              ></path>
            </svg>
          </a>
          <ContentContainer
            onClick={
              (/* e */) => {
                // TODO: Look better into issues with Select lib and stopPropagation
                // e.stopPropagation()
              }
            }
          >
            <Component {...overlayProps} close={close} />
          </ContentContainer>
        </div>
      </div>
    </RemoveScroll>
  )
}
OverlayBase.propTypes = {
  name: PropTypes.string.isRequired,
  Component: PropTypes.node.isRequired,
}

export const withOverlay = (name, Component) => (props) =>
  OverlayBase({ name, Component, ...props })
