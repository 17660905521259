import { DefaultLayout } from './default'

import style from './style'

const panelClasses = style.combine(
  'col-12 col-lg-6 col-xl-4',
  'default-panel',
  'chronological-panel'
)
export const ChronologicalLayout = ({ className, ...props }) =>
  DefaultLayout({ className: style.extend(panelClasses, className), ...props })
ChronologicalLayout.sort = 'publication_date'
ChronologicalLayout.dateFilter = true

export default ChronologicalLayout
