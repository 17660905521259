import BaseLink from './base'
import { requireAtLeastOne } from '../../util/props'
import { useTopic } from 'store/topic'

const href = '/topic/[topic]'
const analyticsCategory = 'topic'

export const TopicLink = ({ id, slug = null, ...props }) => {
  let as = '',
    valid = true,
    title = slug || id
  if (!id && !!slug) {
    as = `/topic/${slug}`
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const topic = useTopic(id)
    valid = !!topic.id
    as = valid && `/topic/${topic.slug}`
    title = valid && (topic.name || id)
  }

  return BaseLink({
    analyticsCategory,
    valid,
    href,
    as,
    title,
    ...props,
  })
}
TopicLink.propTypes = {
  id: requireAtLeastOne('id', 'slug'),
  slug: requireAtLeastOne('id', 'slug'),
}

export default TopicLink
