import ReactGA from 'react-ga'

import { withQuery } from './api'
import getConfig from 'next/config'
import { createLogger } from 'util/log'
const {
  publicRuntimeConfig: { analyticsID },
} = getConfig()

const logger = createLogger(
  'Analytics',
  () => !!analyticsID && typeof window !== 'undefined' && !!window.ga
)

export const initializeAnalytics = () => {
  logger.log('Initializing', analyticsID)
  if (!analyticsID) return
  ReactGA.initialize(analyticsID, {
    titleCase: false,
  })
}

export const logPageView = () => {
  logger.log('Pageview', window.location.pathname)
  if (!analyticsID || !window.ga) return
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname + window.location.search)
}

export const logSearch = ({ ...search }) => {
  const url = withQuery(window.location.pathname, { ...search })
  logger.log('Searchview', url)
  if (!analyticsID) return
  ReactGA.pageview(url)
}

export const set = (data) => {
  logger.log('Setting', data)
  if (!analyticsID || !window.ga) return
  ReactGA.set(data)
}

export const event = (data) => {
  logger.log(`Event: ${data.category}.${data.action} ${data.label ? data.label : ''}`)
  if (!analyticsID || !window.ga) return
  ReactGA.event(data)
}
