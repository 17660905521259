import classnames from 'classnames'
import Typography from 'components/typography'
import LinkArrow from 'components/link-arrow'
import Image from 'components/image'
import { ArticleLink } from 'components/link'
import { useArticleExt } from 'store/article'

import style from './style'

const panelClasses = style.combine('col-12', 'icon-panel')
export const CondensedIconLayout = ({ id, className = panelClasses, layout, onItemClick }) => {
  const { title, description, image } = useArticleExt(id)

  return (
    <div className={classnames(className, `${layout}-panel`)}>
      <ArticleLink id={id} onClick={onItemClick} className={style('card')}>
        {image && (
          <div className={style('image')}>
            <Image images={[image]} resizeProps={{ w: 350, h: 350 }} alt="" />
          </div>
        )}
        <div>
          <Typography variant="h2" className={style('title')}>
            {title}
          </Typography>
          <Typography variant="bodyLarge" className={style('desc')}>
            {description}
          </Typography>
          <LinkArrow>Read More</LinkArrow>
        </div>
      </ArticleLink>
    </div>
  )
}

CondensedIconLayout.page_size = 50

export default CondensedIconLayout
