import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk'
import { Container } from 'components/container'
import Typography from 'components/typography'
import cx from 'classnames'

import getConfig from 'next/config'
const {
  publicRuntimeConfig: {
    paths: { wwwUrl = 'https://www.eveonline.com' },
  },
} = getConfig()

import style from './footer.module.scss'

const Footer = (): JSX.Element => {
  const SocialMediaSection = (): JSX.Element => (
    <div className={style.social}>
      <div className={cx(style.sm, style.facebook)}>
        <a href="https://www.facebook.com/eveonline/" title="Facebook" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
      </div>
      <div className={cx(style.sm, style.t)}>
        <a href="https://twitter.com/eveonline/" title="Twitter" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <div className={cx(style.sm, style.yt)}>
        <a
          href="https://www.youtube.com/channel/UCwF3VyalTHzL0L-GDlwtbRw/"
          title="YouTube"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      <div className={cx(style.sm, style.tw)}>
        <a href="https://www.twitch.tv/ccp" title="Twitch" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitch} />
        </a>
      </div>
      <div className={cx(style.sm, style.ig)}>
        <a href="https://www.instagram.com/eveonline/" title="Instagram" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
      <div className={cx(style.sm, style.vk)}>
        <a href="https://vk.com/eveonline" title="VK" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faVk} />
        </a>
      </div>
    </div>
  )

  const CopyRightAndPegi = (): JSX.Element => (
    <div className={style.copyright}>
      <div className={style.copyright}>
        Copyright © CCP 1997-
        {new Date().getFullYear()}
      </div>
      <div className={style.pegi}>
        <a id="pegi-info" href="http://www.pegi.info/" title="Visit the PEGI webpage">
          <img
            src="https://web.ccpgamescdn.com/aws/eveonline/images/pegiinfo.png"
            width="71"
            height="50"
            alt="The PEGI info logo"
          />
        </a>
      </div>
    </div>
  )

  return (
    <footer className={style.footer}>
      <Container>
        <div className={style.inner}>
          <div className={style.top}>
            <Typography variant="h3">Follow EVE Online</Typography>
            <SocialMediaSection />
          </div>
          <div className={style.colWrapper}>
            <ul>
              <li>Play EVE Online</li>
              <li>
                <a href="https://www.eveonline.com/signup" rel="noreferrer" target="_blank">
                  Play for Free
                </a>
              </li>
              <li>
                <a href={`${wwwUrl}/download`}>Download the Game</a>
              </li>
              <li>
                <a href="https://store.eveonline.com/#omega" rel="noreferrer" target="_blank">
                  Upgrade to Omega
                </a>
              </li>
              <li>
                <a
                  href="https://support.eveonline.com/hc/en-us/articles/5885219196828-System-Requirements"
                  target="_blank"
                  rel="noreferrer"
                >
                  System Requirements
                </a>
              </li>
              <li>
                <a href={`${wwwUrl}/eve-academy`}>Starter Guide for Beginners</a>
              </li>
              <li>
                <a href={`${wwwUrl}/eve-academy/excel-add-in`}>EVE Online add-in for Excel</a>
              </li>
              <li>
                <a href="https://secure.eveonline.com/RecoverAccount">Recover account</a>
              </li>
            </ul>
            <ul>
              <li>Current Players</li>
              <li>
                <a
                  href="https://secure.eveonline.com/account"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Account Management
                </a>
              </li>
              <li>
                <a href="https://support.eveonline.com/" target="_blank" rel="noopener noreferrer">
                  Support
                </a>
              </li>
              <li>
                <a href="https://forums.eveonline.com/" target="_blank" rel="noopener noreferrer">
                  Forums
                </a>
              </li>
              <li>
                <a
                  href="https://developers.eveonline.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Developers
                </a>
              </li>
              <li>
                <a href={`${wwwUrl}/recruit`} data-id="a4c097ec">
                  Recruit a Friend
                </a>
              </li>
              <li>
                <a href={`${wwwUrl}/return`}>Return to EVE</a>
              </li>
              <li>
                <a href={`${wwwUrl}/partners`}>Partnership Program</a>
              </li>
              <li>
                <a href="https://secure.eveonline.com/transfer-character">Character Transfer</a>
              </li>
              <li>
                <a href="https://secure.eveonline.com/third-party-retailers">3rd Party Retailers</a>
              </li>
            </ul>
            <ul>
              <li>Worlds of EVE</li>
              <li>
                <a href={`${wwwUrl}/fanfest`} target="_blank" rel="noopener noreferrer">
                  EVE Fanfest
                </a>
              </li>
              <li>
                <a href="https://merch.eve.com/" target="_blank" rel="noopener noreferrer">
                  EVE Merchandise
                </a>
              </li>
              <li>
                <a href={`${wwwUrl}/discovery`} target="_blank" rel="noopener noreferrer">
                  Project Discovery
                </a>
              </li>
            </ul>
            <ul>
              <li>Site Info</li>
              <li>
                <a
                  href="https://ccpgames.com/company/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About CCP
                </a>
              </li>
              <li>
                <a
                  href="https://community.eveonline.com/support/policies/terms-of-service-en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="https://community.eveonline.com/support/policies/privacy-cookies-policy-en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                {/* NOTE: Hard-coded category slug: `game-world-news` */}
                <a href="/feed/new-eden-news.rss" target="_blank" rel="noopener noreferrer">
                  RSS Feed
                </a>
              </li>
            </ul>
          </div>
          <CopyRightAndPegi />
        </div>
      </Container>
    </footer>
  )
}

export default Footer
