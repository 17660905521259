import { toInt } from 'util/conversion'
import ArticleType, { ArticleWithContent } from './types/ts/article'
import AuthorType from './types/ts/author'
import CategoryType from './types/ts/category'
import OverlayType from './types/ts/overlay'
import { RelatedQueryType } from './types/ts/related'
import { SearchQueryType } from './types/ts/search'
import TopicType from './types/ts/topic'

interface rawPageDataType extends Record<string, any> {
  page?: number | string
  pages?: number | string
  total?: number | string
  results?: number | string
  status?: string
}
export interface pageDataType {
  page: number
  pages: number
  total: number
  results: number
  status?: string
  articles: ArticleType[]
}
export const pageData: pageDataType = {
  page: 0,
  pages: 0,
  total: 0,
  results: 0,
  status: 'empty',
  articles: [],
}

export const getPageData = ({
  page,
  pages,
  total,
  results,
  articles,
}: rawPageDataType): pageDataType => ({
  page: toInt(page, 1),
  pages: toInt(pages, 1),
  total: toInt(total, 0),
  results: toInt(results, 0),
  articles: articles as ArticleType[],
})

export const stripPageData = <T = any>(item: rawPageDataType) => {
  if (!item) return item
  const { page, pages, total, results, articles, status, ...rest } = item // eslint-disable-line no-unused-vars
  return rest as T
}

export const uniqueList = (items: any[]): any[] => items.filter((v, i, a) => a.indexOf(v) === i)

export const stripObjPageData = <T = any>(item: Record<string, T>): Record<string, T> =>
  Object.entries(item).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: stripPageData(val as any) }),
    {}
  )

export const validCategories = (category: Record<string, CategoryType>) => {
  return Object.fromEntries(Object.entries(category).filter(([, { id }]) => !!id))
}

interface WithSlug {
  slug: string
}

export const passArgs = (state: any, props: any) => props // eslint-disable-line no-unused-vars
export const byId = <T = any>(items: Record<string, T>, id: string): T => items[id]
export const matchSlug = <T extends WithSlug = ArticleType | CategoryType | TopicType | AuthorType>(
  items: Record<string, T>,
  slug: string
) => Object.values(items).find((item) => item.slug === slug)

export const articleState = (state: any): Record<string, ArticleType> => state.article.articles
export const contentState = (state: any): Record<string, ArticleWithContent> =>
  state.article.content
export const authorState = (state: any): Record<string, AuthorType> => state.author
export const topicState = (state: any): Record<string, TopicType> => state.topic
export const categoryState = (state: any): Record<string, CategoryType> => state.category
export const heroState = (state: any) => state.hero
export const searchState = (state: any): SearchQueryType & rawPageDataType => state.search
export const relatedState = (state: any): RelatedQueryType & rawPageDataType => state.related
export const overlayState = (state: any): Record<string, OverlayType> => state.overlay

export const states = {
  article: articleState,
  content: contentState,
  author: authorState,
  topic: topicState,
  category: categoryState,
  hero: heroState,
  search: searchState,
  related: relatedState,
}
