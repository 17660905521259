import React from 'react'
import getConfig from 'next/config'

import Typography from 'components/typography'
import { ArticleLink } from '../../../link'

import style from './style'
import { useArticleExt } from 'store/article'
import { defaultPropTypes } from './default'

import { getImage } from 'util/image'

const {
  publicRuntimeConfig: { defaultArticleBanner },
} = getConfig()

const panelClasses = style.combine('col-12', 'news-panel')

export const SidebarLayout = ({ id, className = panelClasses, onItemClick, layout }) => {
  const { category, title, image: articleImage, listingImage } = useArticleExt(id)
  // TODO: Add resize parameters as needed as per `getImage` specs
  const image = getImage([listingImage, articleImage, category.image], defaultArticleBanner, {
    w: 130,
  })

  return (
    <div className={style.combine(className, `${layout}-panel`, 'sidebar-listing')}>
      <ArticleLink onClick={onItemClick} id={id} className={style('card')}>
        <div className={style('imgWrapper')}>{image && <img src={image} alt="" />}</div>
        <Typography variant="h3" className={style('title')}>
          {title}
        </Typography>
      </ArticleLink>
    </div>
  )
}
SidebarLayout.propTypes = defaultPropTypes
SidebarLayout.page_size = 25

export default SidebarLayout
