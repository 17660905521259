import React from 'react'
import cx from 'classnames'
import style from './typography.module.scss'

const variants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  tagline: 'h2',
  body: 'p',
  bodyLarge: 'p',
}

interface Props {
  variant: keyof typeof variants
  children: React.ReactNode
  className?: string
  uppercase?: boolean
}

const Typography = ({ variant, children, uppercase, className }: Props): JSX.Element => {
  const Component = variant ? variants[variant] : 'p'

  return (
    <Component
      className={cx(
        style.typography,
        { [style[variant]]: variant, [style.uppercase]: uppercase },
        className
      )}
    >
      {children}
    </Component>
  )
}

export default Typography
