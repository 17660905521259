import { createContext, useContext, useState, useEffect } from 'react'
import AdGlareModel from './adGlareModel'
import { Ads } from './api/getAds'

const initialModel: AdGlareModel = {
  response: {
    success: 1,
    campaigns: [
      {
        cID: '0',
        creative_type: 'img',
        creative_data: {
          click_url: 'url',
          image_url: 'img url',
          landing_url: 'landingUrl',
        },
      },
    ],
  },
}

const AppContext = createContext<AdGlareModel>(initialModel)

export function AppWrapperContext({ children }) {
  const [ads, setAds] = useState<AdGlareModel | null>(null)

  useEffect(() => {
    ;(async () => {
      Ads.getAds().then((response) => {
        if (response && response?.response?.campaigns?.length > 0) {
          setAds(response)
        }
      })
    })()
  }, [])

  return <AppContext.Provider value={ads}>{children}</AppContext.Provider>
}

export function useAppWrapperContext() {
  return useContext(AppContext)
}
