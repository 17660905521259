import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/menu'
import * as propTypes from './types/menu'
import { hookHelper } from 'util/store'
import MenuGroupType from './types/ts/menu'
import { Dispatch } from 'redux'
const bindActions = actionBinder(actions)
export { propTypes }

export const fetchMenuPage =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<MenuGroupType>('/menu-groups/', {
      dispatchPre: actions.preFetch,
      dispatchPost: actions.postFetch,
      dispatchFail: actions.failFetch,
      dispatch,
      ...params,
    })
  }

export const fetchFullMenu =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.all<MenuGroupType>('/menu-groups/', {
      dispatchPre: actions.preFetch,
      dispatchPost: actions.postFetch,
      dispatchFail: actions.failFetch,
      dispatchStart: actions.startFetch,
      dispatchDone: actions.doneFetch,
      dispatch,
      ...params,
    })
  }

export const fetchMenuIfNeeded =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    const {
      menu: { state },
    } = getState()
    if (state !== 'loaded') {
      await dispatch(fetchFullMenu({ ...params }))
    }
  }

export const [useMenu, getMenu] = hookHelper<MenuGroupType, [id: string]>(
  ({ menu }, id) => menu[id]
)
export const [useMenus, getMenus] = hookHelper<Record<string, MenuGroupType>>(({ menu }) => menu)

const initialState = {
  state: 'empty',
}

export const mapDispatchToProps = {
  fetchMenuPage,
  fetchFullMenu,
  fetchMenuIfNeeded,
}

export const reducer = reducerFromActions(actions, initialState)
