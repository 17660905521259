const Logo = (): JSX.Element => (
  <svg
    width="130"
    fill="#fff"
    height="100%"
    viewBox="0 0 130 51"
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18.1038H37.2437V11.7624H0V18.1038ZM0 8.53815H6.73212C6.73212 8.53815 6.73212 6.95208 6.73212 6.36165C7.68145 6.36165 37.2437 6.36165 37.2437 6.36165V0.0144653H0V8.53815ZM6.73212 21.2991H0V29.8459H37.2437V23.5016C37.2437 23.5016 7.68145 23.5016 6.73212 23.5016C6.73212 22.9083 6.73212 21.2991 6.73212 21.2991ZM91.257 21.2991H84.5278V29.8459H121.769V23.5016C121.769 23.5016 92.2035 23.5016 91.2541 23.5016C91.257 22.9083 91.257 21.2991 91.257 21.2991ZM60.893 22.8215C60.0942 21.4409 47.7211 0.0144653 47.7211 0.0144653H40.3985L60.893 35.6779L81.373 0.0144653H74.0447C74.0447 0.0144653 61.6889 21.4409 60.893 22.8215ZM84.5307 0.0144653V8.53815H91.2599C91.2599 8.53815 91.2599 6.95208 91.2599 6.36165C92.2092 6.36165 121.774 6.36165 121.774 6.36165V0.0144653H84.5307ZM84.5307 18.1038H121.772V11.7624H84.5307V18.1038Z"
      fill="#fff"
    ></path>
    <path
      d="M24.5233 45.8484C24.5233 45.3535 24.6159 45.0149 24.8041 44.8296C24.9893 44.6473 25.3482 44.5547 25.8807 44.5547H29.9009C30.4364 44.5547 30.7981 44.6444 30.9863 44.8296C31.1715 45.012 31.267 45.3506 31.267 45.8484V48.7427C31.267 49.2463 31.1715 49.5879 30.9863 49.7673C30.7981 49.9496 30.4364 50.0423 29.9009 50.0423H25.8836C25.3482 50.0423 24.9922 49.9525 24.807 49.7673C24.6188 49.5879 24.5262 49.2463 24.5262 48.7427V45.8484H24.5233ZM25.7997 49.009H29.9935V45.533H25.7997V49.009Z"
      fill="#fff"
    ></path>
    <path
      d="M39.5099 50.0394V44.5547H40.3811L44.245 47.7876C44.3753 47.8976 44.4939 47.996 44.5923 48.0886C44.6936 48.187 44.7862 48.2825 44.8702 48.3809C44.8499 48.077 44.8354 47.8571 44.8297 47.7297C44.8239 47.5995 44.8181 47.5011 44.8181 47.4316V44.5547H45.9613V50.0394H45.0901L41.0613 46.6502C40.9629 46.5633 40.879 46.4852 40.8066 46.4157C40.7342 46.3463 40.6677 46.2768 40.604 46.2015C40.6214 46.3723 40.6329 46.5315 40.6445 46.682C40.6503 46.8325 40.6532 46.9627 40.6532 47.0727V50.0365H39.5099V50.0394Z"
      fill="#fff"
    ></path>
    <path
      d="M54.3229 50.0394V44.5547H55.5993V49.0061H59.7121V50.0365H54.3229V50.0394Z"
      fill="#fff"
    ></path>
    <path d="M67.1447 50.0394V44.5547H68.421V50.0394H67.1447Z" fill="#fff"></path>
    <path
      d="M76.7769 50.0394V44.5547H77.6509L81.5148 47.7876C81.648 47.8976 81.7637 47.996 81.865 48.0886C81.9663 48.187 82.0589 48.2825 82.14 48.3809C82.1197 48.077 82.1052 47.8571 82.0995 47.7297C82.0908 47.5995 82.0879 47.5011 82.0879 47.4316V44.5547H83.2282V50.0394H82.357L78.3282 46.6502C78.2298 46.5633 78.1459 46.4852 78.0706 46.4157C78.0011 46.3463 77.9317 46.2768 77.8709 46.2015C77.8883 46.3723 77.9027 46.5315 77.9085 46.682C77.9201 46.8325 77.923 46.9627 77.923 47.0727V50.0365H76.7769V50.0394Z"
      fill="#fff"
    ></path>
    <path
      d="M91.5869 50.0394V44.5547H97.1961V45.4895H92.8662V46.7109H95.3871V47.6458H92.8662V49.0032H97.2481V50.0336H91.5869V50.0394Z"
      fill="#fff"
    ></path>
    <path
      d="M130 2.28649C130 3.57156 129.004 4.57298 127.713 4.57298C126.423 4.57298 125.427 3.57156 125.427 2.28649C125.427 1.00142 126.423 0 127.713 0C129.004 0 130 1.00142 130 2.28649ZM125.783 2.28649C125.783 3.38053 126.605 4.24882 127.713 4.24882C128.819 4.24882 129.644 3.38343 129.644 2.28649C129.644 1.19245 128.819 0.327055 127.713 0.327055C126.608 0.324161 125.783 1.19245 125.783 2.28649Z"
      fill="#fff"
    ></path>
    <path
      d="M128.055 2.39645C128.402 2.33567 128.7 2.13307 128.7 1.67867C128.7 1.21848 128.443 0.966675 127.864 0.966675H126.877V3.5368H127.195V2.39645H127.728L128.452 3.5397H128.822L128.055 2.39645ZM127.612 2.12729H127.195V1.23584H127.774C128.087 1.23584 128.385 1.31978 128.385 1.67867C128.385 2.11571 128 2.12729 127.612 2.12729Z"
      fill="#fff"
    ></path>
    <defs>
      <clipPath id="clip0">
        <rect width="130" height="50.0394" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
)

export default Logo
