import React from 'react'
import { useRouter } from 'next/router'
import Menu from './menu'
import Footer from './footer'
import OverlayContainer from 'util/overlay'
import { SearchOverlay } from '../search'
import { RelatedOverlay } from '../related'
import { Hero } from '../hero'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { defaultBodyBackground },
} = getConfig()

interface LayoutProps {
  children: React.ReactElement | React.ReactElement[]
}

const ErrorElement = () => {
  return (
    <div
      id="test"
      style={{
        display: 'none',
        position: 'absolute',
        right: '1em',
        bottom: '1em',
        userSelect: 'none',
      }}
      onClick={(e) => {
        throw new Error('Unhandled exception')
      }}
    >
      π
    </div>
  )
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()
  const isArticle = router.query?.article

  return (
    <>
      <Menu />
      <Hero />
      <div style={{ backgroundImage: `url(${!isArticle ? defaultBodyBackground : ''})` }}>
        {children}
        <Footer />
      </div>
      <ErrorElement />
      <OverlayContainer>
        <SearchOverlay />
        <RelatedOverlay />
      </OverlayContainer>
    </>
  )
}
export default Layout
