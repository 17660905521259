import { fetchMissingArticles } from './article'
import { fetchMissingTopics } from './topic'
import { fetchMissingAuthors } from './author'
import { fetchMissingCategories } from './category'
import ArticleType from './types/ts/article'
import { Dispatch } from 'redux'

const initialState = {}

interface missingItems {
  authors: string[]
  categories: string[]
  topics: string[]
}
const fetchMissing = (articles: ArticleType[]) => async (dispatch: Dispatch<any>) => {
  const totals = articles.reduce<missingItems>(
    ({ authors, categories, topics }: missingItems, article) => {
      if (article.author) {
        authors.push(article.author)
      }
      if (article.category) {
        categories.push(article.category)
      }
      topics = [...topics, ...(article.topics || [])]
      return { authors, categories, topics }
    },
    { authors: [], categories: [], topics: [] }
  )

  return await Promise.all([
    dispatch(fetchMissingAuthors(totals.authors || [])),
    dispatch(fetchMissingCategories(totals.categories || [])),
    dispatch(fetchMissingTopics(totals.topics || [])),
  ])
}

export const mapDispatchToProps = {
  fetchMissingArticles,
  fetchMissingAuthors,
  fetchMissingCategories,
  fetchMissingTopics,
  fetchMissing,
}

export const reducer = (state = initialState) => state // eslint-disable-line no-unused-vars
