import React, { useEffect } from 'react'

import { RelatedListing } from '../layout/listing'
import { withDispatch } from 'store'

import ArticleType from 'store/types/ts/article'

interface Props {
  data: string
  fetchRelatedPage: (article: string) => ArticleType[]
  fetchMissing: () => void
}

const RelatedOverview = ({ data, fetchRelatedPage, fetchMissing }: Props): JSX.Element => {
  useEffect(() => {
    const search = async () => {
      const [, items] = await fetchRelatedPage({ article: data }) // eslint-disable-line no-unused-vars

      await fetchMissing(items)
    }

    search()
  })

  return <RelatedListing id={data} layout="sidebar" />
}

export default withDispatch({
  related: ['fetchRelatedPage'],
  shared: ['fetchMissing'],
})(RelatedOverview)
