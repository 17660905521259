export interface RequestInitWithTimeout extends RequestInit {
  timeout?: number
}

const DEFAULT_TIMEOUT = 15000

/**
 * A variant of fetch() with timeout functionality.
 * @param param1.timeout The amount of miliseconds to wait before timing out this request
 */
export const fetchTimeout = (
  url: RequestInfo,
  { signal, timeout, ...options }: RequestInitWithTimeout = {}
): Promise<Response> => {
  const controller = new AbortController()
  const promise = fetch(url, { signal: controller.signal, ...options })
  if (signal) signal.addEventListener('abort', () => controller.abort())
  const timer = setTimeout(() => controller.abort(), timeout || DEFAULT_TIMEOUT)
  return promise.finally(() => clearTimeout(timer))
}

export default fetchTimeout
