import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import { actionReducer, actionBinder, reducerFromActions } from '../util/api'
import { hookHelper } from 'util/store'
import { overlayState, passArgs, byId } from './common'
import OverlayType from './types/ts/overlay'
import { Dispatch } from 'redux'

const mergeData = ({ data: oldData }: { data: any }, newData: any) => {
  if (newData === undefined) return oldData
  if (typeof newData === 'object') return { ...oldData, ...newData }
  return newData
}

const initial = {
  visible: false,
  closing: false,
  data: {},
}

const overlayReduce = actionReducer(
  'UPDATE_OVERLAY',
  ({ name, ...state }: Record<string, any>) => ({ name, ...state }),
  (state, { name, data, ...newState }) => ({
    ...state,
    [name]: {
      ...initial,
      ...(state[name] || initial),
      ...newState,
      data: mergeData(state[name] || initial, data),
    },
  })
)

const actions = {
  overlayReduce,
}
const bindActions = actionBinder(actions)

export const setOverlay =
  ({ name, ...state }: Record<string, any>) =>
  (dispatch: Dispatch<any>) =>
    bindActions(dispatch).overlayReduce({ name, ...state })
export const setOverlayData = (name: string, data: any) => setOverlay({ name, data })
export const showOverlay = (name: string, data?: any) =>
  setOverlay({ name, visible: true, closing: false, data })
export const hideOverlay = (name: string, data?: any) =>
  setOverlay({ name, visible: true, closing: true, data })
export const closeOverlay =
  (name: string, closeTime = 250) =>
  (dispatch: Dispatch<any>) => {
    dispatch(hideOverlay(name))
    setTimeout(() => dispatch(setOverlay({ name, visible: false, closing: false })), closeTime)
  }
export const hideAll = () => (dispatch: Dispatch<any>, getState: () => any) => {
  const state = overlayState(getState())
  Object.entries(state)
    .filter(([key, { visible, closing }]) => visible && !closing) // eslint-disable-line no-unused-vars
    .forEach(([key]) => dispatch(closeOverlay(key))) // eslint-disable-line no-unused-vars
}

export const [useOverlay, getOverlay] = hookHelper<OverlayType, [name: string]>(
  createCachedSelector([overlayState, passArgs], byId)(passArgs)
)
export const [useOverlays, getOverlays] = hookHelper<Record<string, OverlayType>, [never]>(
  createSelector(overlayState, (overlay) => overlay)
)

export const mapDispatchToProps = {
  setOverlay,
  setOverlayData,
  showOverlay,
  hideOverlay,
  closeOverlay,
  hideAll,
}

export const reducer = reducerFromActions(actions, { search: initial, related: initial })
