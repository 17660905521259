import React from 'react'
import classnames from 'classnames'
import _truncate from 'lodash/truncate'
import { useArticleExt } from 'store/article'
import Typography from 'components/typography'
import Image from 'components/image'
import { DefaultImageEnum } from 'util/default-image'
import { ArticleLink } from 'components/link'
import LinkArrow from 'components/link-arrow'
import { defaultPropTypes } from './default'
import style from './style'
import ArticleType from 'store/types/ts/article'

const panelClasses = style.combine('col-12', 'large-panel')

interface Props {
  item: ArticleType
  id: string
  className: string
  layout: string
  onItemClick: MouseEvent
}

export const DefaultLayout = ({
  item,
  id,
  className = panelClasses,
  layout,
  onItemClick,
}: Props) => {
  const {
    title,
    description,
    heroImage: articleHeroImage,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = item || useArticleExt(id)

  return (
    <div className={classnames(className, `${layout}-panel`)}>
      <ArticleLink id={id} onClick={onItemClick} className={style('card')}>
        <div className={style('imgWrapper')}>
          <Image
            images={[articleHeroImage]}
            defaultImageName={DefaultImageEnum.ArticleBanner}
            resizeProps={{ w: 1600, h: 600, fm: 'jpg', fl: 'progressive', q: 80, fit: 'fill' }}
            alt=""
          />
        </div>
        <div className={style('content')}>
          <Typography variant="h2" className={style('title')}>
            {title}
          </Typography>
          <Typography variant="bodyLarge" className={style('desc')}>
            {_truncate(description, {
              length: 250,
              omission: '[...]',
            })}
          </Typography>
          <LinkArrow className={style('link')}>Read More</LinkArrow>
        </div>
      </ArticleLink>
    </div>
  )
}
DefaultLayout.propTypes = defaultPropTypes

export default DefaultLayout
