import { DefaultLayout } from './default'

import style from './style'

const panelClasses = style.combine(
  'col-12 col-md-6 col-lg-4 col-xl-3',
  'default-panel',
  'chronological-condensed-panel'
)
export const ChronologicalCondensedLayout = ({ className, ...props }) =>
  DefaultLayout({ className: style.extend(panelClasses, className), ...props })
ChronologicalCondensedLayout.sort = 'publication_date'
ChronologicalCondensedLayout.page_size = 50
ChronologicalCondensedLayout.dateFilter = true

export default ChronologicalCondensedLayout
