import React from 'react'
import PropTypes from 'prop-types'

import Pagination from '../../../pagination'
import DateFilter from '../../../date-filter'
import { SimpleElement } from '../../../container'

import DefaultLayout from './default'
import ChronologicalLayout from './chronological'
import ChronologicalCondensedLayout from './chronological-condensed'
import CondensedLayout from './condensed'
import CondensedIconLayout from './condensed-icon'
import LargeLayout from './large-panel'
import NewsLayout from './news-listing'
import NewsLayoutAuthor from './news-listing-author'
import ResultsLayout from './results'
import SidebarLayout from './sidebar'
import FrontpageTopLayout from './frontpage/top'
import CategoriesLayout from './frontpage/categories'
import EmpiresLayout from './frontpage/empires'

import style from './style'
import { toInt } from 'util/conversion'

const layouts = {
  default: DefaultLayout,
  condensed: CondensedLayout,
  condensedIcon: CondensedIconLayout,
  largePanel: LargeLayout,
  chronological: ChronologicalLayout,
  chronlogicalCondensed: ChronologicalCondensedLayout,
  newsListing: NewsLayout,
  newsListingAuthor: NewsLayoutAuthor,

  results: ResultsLayout,
  sidebar: SidebarLayout,
  categories: CategoriesLayout,
  empires: EmpiresLayout,
}

export {
  DefaultLayout,
  CondensedLayout,
  CondensedIconLayout,
  LargeLayout,
  ChronologicalLayout,
  ChronologicalCondensedLayout,
  NewsLayout,
  NewsLayoutAuthor,
  ResultsLayout,
  SidebarLayout,
  FrontpageTopLayout,
  CategoriesLayout,
  EmpiresLayout,
}

const DefaultListingLayout = DefaultLayout

export const ListingLayout = ({
  type,
  id,
  page = 1,
  pages,
  total,
  results,
  articles,
  layout,
  query: { year, month } = '',
  DefaultLayout = DefaultListingLayout,
  ...props
}) => {
  page = toInt(page, 1)
  if (page < 1) page = 1

  const paginationProps = {
    type,
    id,
    page,
    pages,
    total,
    results,
    query: {
      year,
      month,
    },
  }

  const dateFilterProps = {
    type,
    id,
    year,
    month,
  }

  const Layout = getLayout(layout, DefaultLayout)
  const { WrapComponent = SimpleElement(style.combine('row', 'listing')), dateFilter = false } =
    Layout

  return (
    <>
      {dateFilter && <DateFilter {...dateFilterProps} />}
      <Pagination {...paginationProps} />
      <WrapComponent>
        {articles.map((articleId) => (
          <Layout key={articleId} id={articleId} layout={layout} {...props} />
        ))}
      </WrapComponent>
      <Pagination {...paginationProps} />
    </>
  )
}
ListingLayout.getInitialProps = async ({
  fetch,
  fetchMissing,
  fetchCategoryStats,
  layout,
  page = 1,
  DefaultLayout = DefaultListingLayout,
  year,
  month,
  ...props
}) => {
  if (page < 1) page = 1

  const Layout = getLayout(layout, DefaultLayout)
  const { sort = 'title', order = 'auto', page_size = 24, dateFilter = false } = Layout

  const fetchStats = async () => {
    if (dateFilter) await fetchCategoryStats({ category: props.category })
  }
  const fetchPage = async () => {
    const [, currentPage] = await fetch({ page, sort, order, page_size, year, month, ...props })
    if (fetchMissing) await fetchMissing(currentPage)
  }
  await Promise.all([fetchStats(), fetchPage()])
}
ListingLayout.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  results: PropTypes.number.isRequired,
  articles: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.string,
  DefaultLayout: PropTypes.node,
}

export const getLayout = (layout, def = DefaultListingLayout) => layouts[layout] || def
export default getLayout
