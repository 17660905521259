import { DefaultLayout } from './default'

import style from './style'

const panelClasses = style.combine('col-12 col-md-6 col-lg-4 col-xl-3', 'default-panel')
export const CondensedLayout = ({ className, ...props }) =>
  DefaultLayout({ className: style.extend(panelClasses, className), ...props })
CondensedLayout.page_size = 50

export default CondensedLayout
