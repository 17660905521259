import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { withQuery } from '../../util/api'

import { event } from 'util/analytics'

const defaultPage = 1

export const BaseLink = ({
  valid = false,
  children,
  title,
  analyticsCategory = 'Base',
  analyticsAction = 'link',
  ...props
}) => {
  if (!valid) {
    return <span className="invalid-link">{children}</span>
  }
  let { passHref, prefetch, replace, scroll, shallow, href, as, ...rest } = props
  const {
    search = null,
    query = {},
    page = 0,
    hash = null,
    WrapComponent = null,
    wrapChildren = true,
    onClick,
    ...passProps
  } = rest

  if (search) {
    as = `${as}${search}`
    href = `${href}${search}`
  }
  if (page) {
    if (page != defaultPage) {
      query.page = page
    } else if (query.page) {
      delete query.page
    }
  }
  if (Object.values(query).length > 0) {
    as = withQuery(as, query)
    href = withQuery(href, query)
  }
  if (hash) {
    as = `${as}${hash}`
  }

  if (!children) children = title
  if (wrapChildren) children = <a>{children}</a>

  const child = children ? React.Children.only(children) : null

  const newProps = {
    ...passProps,
    ...child.props,
    onClick: (e) => {
      if (child.props && typeof child.props.onClick === 'function') {
        child.props.onClick(e)
      }
      if (onClick && typeof onClick === 'function' && !e.defaultPrevented) {
        onClick(e)
      }
      if (!e.defaultPrevented && analyticsCategory) {
        event({
          category: analyticsCategory,
          action: analyticsAction,
          label: as,
        })
      }
    },
  }
  const linkChild = React.cloneElement(child, newProps)

  const linkProps = Object.entries({ passHref, prefetch, replace, scroll, shallow, href, as })
    .filter(([key, val]) => val !== undefined) // eslint-disable-line no-unused-vars
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
  if (WrapComponent) {
    return (
      <WrapComponent>
        <Link {...linkProps}>{linkChild}</Link>
      </WrapComponent>
    )
  } else {
    return <Link {...linkProps}>{linkChild}</Link>
  }
}
BaseLink.propTypes = {
  valid: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  analyticsCategory: PropTypes.string,
  analyticsAction: PropTypes.string,

  passHref: PropTypes.bool,
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  href: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired,

  search: PropTypes.string,
  query: PropTypes.object,
  page: PropTypes.number,
  hash: PropTypes.string,
  WrapComponent: PropTypes.node,
  wrapChildren: PropTypes.bool,
  onClick: PropTypes.func,
}

export default BaseLink
