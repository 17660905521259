import { DefaultLayout, ListingLayout } from './layouts'
import { getTopic, useTopic } from 'store/topic'
import { withDispatch } from 'store'
import { getPageData } from 'store/common'

export const TopicListing = ({ id, ...props }) => {
  const topic = useTopic(id)
  return ListingLayout({
    ...topic,
    ...getPageData(topic),
    DefaultLayout,
    type: 'topic',
    id,
    ...props,
  })
}
TopicListing.getInitialProps = async ({
  store,
  id: topic,
  page = 1,
  fetchTopicArticlePage,
  fetchMissing,
}) => {
  const { layout } = getTopic(store, topic)
  await ListingLayout.getInitialProps({
    fetch: fetchTopicArticlePage,
    fetchMissing,
    DefaultLayout,
    page,
    layout,
    topic,
  })
}
export default withDispatch({
  topic: ['fetchTopicArticlePage'],
  shared: ['fetchMissing'],
})(TopicListing)
