import { Console } from 'console'
import getConfig from 'next/config'
const {
  publicRuntimeConfig: { production = false },
} = getConfig()

type LogFunction = (...args: unknown[]) => void
const noop = (...args: unknown[]) => {}

// The list of functions we should patch.
//
// If `prefix` is set to true, the prefix
// passed to createLogger will be prefixed
// to this function.
//
// If `production` is set to false, this
// function will be a noop when we run in
// production mode.
const patchedFunctions = {
  log: {
    prefix: true,
    production: false,
  },
  warning: {
    prefix: true,
    production: false,
  },
  exception: {
    prefix: true,
    production: true,
  },
  error: {
    prefix: true,
    production: true,
  },
  group: {
    prefix: true,
    production: false,
  },
  groupCollapsed: {
    prefix: true,
    production: false,
  },
}

// We extend Console because it has no indexer defined, causing
// ts to think we're doing something wrong when accessing functions
// directly in `createLogger`
interface Logger extends Console {
  [key: string]: LogFunction | unknown
}

/**
 * Returns a log helper that logs messages based on production mode.
 *
 * @param name The prefix to show for log messages
 */
export const createLogger = (name: string): Logger => {
  const prefix = `[${name}]`
  const prefixed =
    (func: LogFunction) =>
    (...args: unknown[]) =>
      func(prefix, ...args)
  return Object.entries(patchedFunctions).reduce(
    (obj: Logger, [key, value]) => {
      const { [key]: func }: Logger = obj
      const newFunc = value.prefix ? prefixed(func as LogFunction) : func
      return {
        ...obj,
        [key]: (value.production || !production ? newFunc : noop) as LogFunction,
      }
    },
    { ...(console as Logger) }
  )
}

export default createLogger
