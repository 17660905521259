import {
  Action,
  ActionCreator,
  ActionCreatorsMapObject,
  AnyAction,
  bindActionCreators,
  Dispatch,
  Reducer,
} from 'redux'

import getConfig from 'next/config'
import { createLogger } from 'util/log'
import { toChunks, toInt } from 'util/conversion'
const { publicRuntimeConfig } = getConfig()
const { apiURL } = publicRuntimeConfig

const logger = createLogger('API')

type QueryParamSingleValue = string | number
type QueryParamArrayValue = string[]
type QueryParamValue = QueryParamSingleValue | QueryParamArrayValue | number[]

export interface QueryParams extends Record<string, QueryParamValue> {}
export interface QueryArrayParams extends Record<string, QueryParamArrayValue> {}

/**
 * Encodes a key/value pair to be used in a query param
 *
 * @param key the key component of this query param
 * @param val The value component of this query param
 * @returns The encoded key=value pair
 */
const encodeURIKeyVal = (key: string, val: QueryParamSingleValue): string =>
  `${encodeURIComponent(key)}=${encodeURIComponent(String(val))}`

/**
 * Encodes a list of key/value pairs based on the given key, and the
 * values for that key, to be used in a query param.
 *
 * @param key The key component of this query param
 * @param val The value components of this query param
 * @returns A list of encoded key=value pairs
 */
const encodeURIKeyArray = (key: string, val: QueryParamArrayValue): string[] =>
  val.map((v) => encodeURIKeyVal(key, v))

/**
 * Encodes a dict of key/value pairs, to be used in a query param.
 *
 * @param dict The key/value dict to be used as query params
 * @returns A list of encoded key=value pairs
 */
const encodeURIDict = (dict: QueryParams): string[] =>
  Object.entries(dict)
    .filter(([k, v]) => !!k && !!v)
    .reduce(
      (obj: string[], [k, v]) =>
        Array.isArray(v)
          ? [...obj, ...encodeURIKeyArray(k, v as string[])]
          : [...obj, encodeURIKeyVal(k, v as string)],
      []
    )

/**
 * Builds a full URL based on query args
 *
 * @param {string} url The base URL to apply to.
 * @param {QueryParams} params The parameters to encode
 * @param {QueryParams} extra Extra parameters to encode. Will be merged with {@link params}.
 * @returns The full URL, including the query params.
 */
export const withQuery = (url: string, params: QueryParams, extra: QueryParams = {}) => {
  const query = encodeURIDict({ ...params, ...extra })
  if (query.length === 0) return url
  const sep = url.includes('?') ? '&' : '?'
  // Add https in front of url if it is missing, next/image does not like if the protocol is missing
  const protocol = url?.startsWith('//') ? 'https:' : ''
  return `${protocol}${url}${sep}${query.join('&')}`
}

interface ExtractHeaders {
  [key: string]: string
}
const defaultExtractHeaders = {
  'X-Pages': 'pages',
  'X-Page': 'page',
  'X-Results': 'results',
  'X-Total': 'total',
}

/**
 * Extracts a set of headers from a repsonse.
 *
 * @param res The Response object to extract the headers from
 * @param headers The headers to extract
 */
export const extractHeaders = (
  res: Response,
  headers: ExtractHeaders = defaultExtractHeaders
): ExtractHeaders => {
  const _default = Object.values(headers).reduce((obj, key) => ({ ...obj, [key]: 0 }), {})
  if (!res.headers) return _default

  return Object.entries(headers)
    .filter(([k]) => res.headers.has(k))
    .reduce(
      (obj, [k, v]) => ({
        ...obj,
        [v]: toInt(res.headers.get(k), 0),
      }),
      _default
    )
}

export type DispatchPre<T> = (target: T) => void
export type DispatchPost<T> = (res: Response, content: any, target: T) => void

interface APIOptions<T = any> extends Omit<RequestInit, 'body' | 'headers'> {
  params?: QueryParams
  extra?: QueryParams
  headers?: QueryParams
  dispatchPre?: DispatchPre<T>
  dispatchPost?: DispatchPost<T>
  dispatchFail?: DispatchPost<T>
  dispatch?: ActionCreator<any>
  body?: Record<string, any> | string
  target?: T
}
export type APIListResponse<T = any> = [Response, T[]]
export type APIRecordResponse<T = any> = [Response, Record<string, T>]
export type APINullResponse = [Response, null]
export type APIResponse<T = any> = APIListResponse<T> | APIRecordResponse<T> | APINullResponse

export type APIAllResponse<T = any> = [Response, T[] | Record<string, T> | null, number]
export type ChunkedAPIResponse<T = any> = T[]

/**
 * Sends an API call
 *
 * @param path The relative path from the API to call.
 * @param param1 Options to be sent to fetch()
 * @param param1.arams Query params for the request.
 * @param param1.extra Extra parameters to merge with `params`.
 * @param param1.dispatchPre This function is called before each request.
 * @param param1.dispatchPost This function is called after each request if it was successful.
 * @param param1.dispatchFail This function is called after each request if it was not successful.
 * @param param1.headers Extra headers to send to the API. The Content-Type will be automatically set if not specified.
 */
export const api = async <T = any>(
  path: string,
  {
    params = {},
    extra = {},
    headers = {},
    dispatchPre = () => {},
    dispatchPost = () => {},
    dispatchFail = () => {},
    dispatch = undefined,
    body = undefined,
    target = null,
    ...rest
  }: APIOptions
): Promise<APIResponse<T>> => {
  const { method = 'GET' } = rest

  if (method === 'POST' && body && typeof body !== 'string') {
    headers = { 'Content-Type': 'application/json', ...headers }
    body = JSON.stringify(body)
  }

  const url = withQuery(`${apiURL}${path}`, params, extra)

  let time = Date.now()
  logger.log('Starting request', method, url, target)

  if (dispatch) {
    dispatch({ type: `API_START_${method}`, url, target })
    dispatchPre(target)
  }

  let res = await fetch(url, {
    body: body as string,
    ...rest,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
  })
  time = Date.now() - time
  let content: Array<T> | Record<string, T>
  try {
    content = (await res.json()) as Array<T> | Record<string, T>
  } catch {
    logger.error('Error parsing JSON')
    return [res, null]
  }

  logger.log('Done with request', method, url, `${time}ms`)

  if (dispatch) {
    dispatch({ type: `API_END_${method}`, url, time })
    if (res.ok) dispatchPost(res, content, target)
    else dispatchFail(res, content, target)
  }

  return [res, content]
}
type targetField = 'body' | 'params'
interface ChunkedOptions {
  chunkSize?: number
  targetField?: targetField | 'auto'
}

/**
 * @see {@link api} for further information.
 * Calls the API at @see {@link path}
 *
 * @param path The API path to call.
 * @param param1 The params to chunk-ify
 * @param param2 Params to send to @see {@link api}
 * @param param3.targetField Where to push the chunked values
 * @param param3.chunkSize The maximum amount of items to send per chunk
 */
api.chunked = async <T = any>(
  path: string,
  chunkedParams: QueryArrayParams,
  { method, ...params }: APIOptions = {},
  { targetField = 'auto', chunkSize = 50 }: ChunkedOptions = {}
): Promise<ChunkedAPIResponse<T>> => {
  const field: targetField =
    targetField === 'auto' ? (method === 'POST' ? 'body' : 'params') : targetField
  let [param, values] = Object.entries(chunkedParams)[0]
  values = values.filter((v, i, a) => a.indexOf(v) === i)

  const baseParams = (params[field] || {}) as Record<string, any>

  const responses = (await Promise.all(
    toChunks(values, chunkSize).map<Promise<APIResponse<T>>>((chunk) =>
      api<T>(path, { method, ...params, [field]: { ...baseParams, [param]: chunk } })
    )
  )) as APIResponse<T>[]
  return responses.reduce(
    (obj, [, items]) => [...obj, ...(items as T[])],
    [] as ChunkedAPIResponse<T>
  ) // eslint-disable-line no-unused-vars
}

export type DispatchStart = () => void
export type DispatchDone = (res: Response, data: any[], pages: number) => void
interface APIAllOptions<T = any> extends APIOptions<T> {
  dispatchStart?: DispatchStart
  dispatchDone?: DispatchDone
}
interface APIAllPagingOptions {
  pageParam?: string
  pageHeader?: string
}

/**
 * @see {@link api} for further information.
 * @param path The relative path from the API to call.
 * @param param1 @see {@link api} for further information.
 * @param param1.dispatchStart This function is called before the first request.
 * @param param1.dispatchDone This function is called after the last request.
 * @param param2 These are specific parameters for how to obtain page information from the requests.
 * @param pageParam The 'page' parameter name to send to the API.
 * @param pageHeader The 'X-Pages' header name that the API returns with the number of total pages.
 */
api.all = async <T = any>(
  path: string,
  {
    dispatchStart = () => {},
    dispatchDone = (res, data, pages) => {}, // eslint-disable-line no-unused-vars
    dispatch = undefined,
    ...params
  }: APIAllOptions = {},
  { pageParam = 'page', pageHeader = 'X-Pages' }: APIAllPagingOptions = {}
): Promise<APIAllResponse<T>> => {
  let data: T[] = []

  dispatchStart()

  let [res, items] = (await api(path, { ...params, dispatch, extra: { [pageParam]: 1 } })) as any[]

  if (res.ok) data = [...items]
  else {
    dispatchDone(res, [], 0)
    return [res, [], 0]
  }

  const headers = extractHeaders(res, { [pageHeader]: 'page' })
  const pageCount = toInt(headers.page, 0)

  const promises = []

  for (let i = 2; i < pageCount; i++) {
    promises.push(api<T>(path, { ...params, dispatch, extra: { [pageParam]: i } }))
  }
  let page = null
  const chunks = toChunks(promises, 10)
  for (let i = 0; i < chunks.length; i++) {
    const items = chunks[i]
    const responses = await Promise.all(items)
    responses.map((response) => {
      ;[res, page] = response
      if (res.ok) {
        data = [...data, ...(page as T[])]
      }
    })
  }

  dispatchDone(res, data, pageCount)

  return [res, data, pageCount]
}

/**
 * Converts a list of objects into a dict of objects by their IDs
 * @param {Object[]} list The list of objects to convert
 * @param {string} keyAttr The ID key attribute to map by
 *   Note that this property can be overridden by the value of the objectitself.
 */
export const listToDict = <T extends Record<string, any> = Record<string, any>>(
  list: Array<T> = [],
  keyAttr: keyof T = 'id'
): Record<string, T> => Object.fromEntries<T>(list.map((item) => [item[keyAttr] as string, item]))

/**
 * Prepares a set of actions to bind to a dispatch function.
 * @param {Object} actions The actions to bind
 */
export const actionBinder =
  <T extends ActionCreatorsMapObject<any>>(actions: T) =>
  (dispatch: Dispatch<any>): typeof actions =>
    bindActionCreators(actions, dispatch) as T

type ActionFunc<P extends Array<any> = any[], R = any> = (...args: P) => R

/**
 * Helper function to easily create an action/reducer combo.
 * @param type The name for the action to use.
 * @param actionFunc The action function. This will receive all params from the caller,
 *   and should return the action-specific information to be passed on
 * @param reducerFunc The reducer function. This will be called when this action is
 *   being processed, and will be passed both the current state, and the action with the data as
 *   returned by {@link actionFunc}.
 */
export const actionReducer = <T = any, P extends Array<any> = any[], R = any>(
  type: string,
  actionFunc: ActionFunc<P, R> = (...params: P) => ({} as R),
  reducerFunc?: Reducer<T, ReturnType<typeof actionFunc> & Action<any>>
) => {
  const obj = (...params: P) => ({ ...actionFunc(...params), type })

  obj.reducer = (reducerFunc ? reducerFunc : (state) => state) as Reducer<
    T,
    ReturnType<typeof actionFunc> & Action<any>
  >
  obj.type = type

  return obj
}

export const actionListener = (type: string, reducer: Reducer) => {
  return { type, reducer }
}

/**
 * Creates a reducer from an object of actions created with {@link actionReducer}.
 * @param actions The actions mapping.
 * @param initialState The initial state to apply.
 */
export const reducerFromActions = (actions: Record<string, AnyAction>, initialState: any) => {
  const typeReducers = Object.entries(actions).reduce(
    (obj, [, val]) => ({ ...obj, [val.type]: val.reducer }),
    {}
  ) as Record<string, Reducer>
  const reducer = (state = initialState, action: AnyAction) => {
    const actionReducer = typeReducers[action.type] || ((state) => state)
    return actionReducer(state, action)
  }
  reducer.actions = Object.keys(typeReducers)
  return reducer
}
