/**
 * Helper for getting default image from one place.
 */

import getConfig from 'next/config'

// Get all default images here and return correct based on enumerator
const {
  publicRuntimeConfig: {
    defaultCategoryBanner,
    defaultAuthorBanner,
    defaultTopicBanner,
    defaultArticleBanner,
    defaultArticleDetailView,
    defaultArticleImage,
    defaultBodyBackground,
    defaultArticleNeutralBackground,
    error404Banner,
    error500Banner,
    errorBanner,
  },
} = getConfig()

export enum DefaultImageEnum {
  CategoryBanner,
  AuthorBanner,
  TopicBanner,
  ArticleBanner,
  ArticleDetailView,
  ArticleImage,
  ArticleNeutralBackground,
  BodyBackground,
  NotFoundBanner,
  InternalServerErrorBanner,
  ErrorBanner,
}

// Can set what is default if nothing is set, could be empty also
const defaultImage = defaultArticleBanner

export function getDefaultImage(image?: DefaultImageEnum): string {
  switch (image) {
    case DefaultImageEnum.CategoryBanner:
      return defaultCategoryBanner
    case DefaultImageEnum.AuthorBanner:
      return defaultAuthorBanner
    case DefaultImageEnum.TopicBanner:
      return defaultTopicBanner
    case DefaultImageEnum.ArticleBanner:
      return defaultArticleBanner
    case DefaultImageEnum.ArticleDetailView:
      return defaultArticleDetailView
    case DefaultImageEnum.ArticleImage:
      return defaultArticleImage
    case DefaultImageEnum.BodyBackground:
      return defaultBodyBackground
    case DefaultImageEnum.ArticleNeutralBackground:
      return defaultArticleNeutralBackground
    case DefaultImageEnum.NotFoundBanner:
      return error404Banner
    case DefaultImageEnum.InternalServerErrorBanner:
      return error500Banner
    case DefaultImageEnum.ErrorBanner:
      return errorBanner
    default:
      return defaultImage
  }
}
