import { createCachedSelector } from 're-reselect'

import getConfig from 'next/config'
const {
  publicRuntimeConfig: { categoriesID },
} = getConfig()

import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/article'
import * as propTypes from './types/article'
import { hookHelper } from 'util/store'
import {
  articleState,
  contentState,
  topicState,
  categoryState,
  authorState,
  stripPageData,
  passArgs,
  byId,
} from './common'
import { Dispatch } from 'redux'
import ArticleType, {
  ArticleContent,
  ArticleExtType,
  ArticleExtWithContent,
  ArticleWithContent,
} from './types/ts/article'
import CategoryType from './types/ts/category'
import AuthorType from './types/ts/author'
import TopicType from './types/ts/topic'
const bindActions = actionBinder(actions) // @ts-ignore
export { propTypes }

export const fetchAllArticles =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.all<ArticleType>(`/articles/`, {
      dispatchPre: actions.preFetchSet,
      dispatchPost: actions.postFetchSet,
      dispatchFail: actions.failFetchSet,
      dispatch,
      ...params,
      params: {
        sort: 'updated_at',
        page_size: '100',
        ...params.params,
      },
    })
  }

// TODO: Change to POST and search on categories
export const fetchArticles =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleType>(`/articles/`, {
      dispatchPre: actions.preFetchSet,
      dispatchPost: actions.postFetchSet,
      dispatchFail: actions.failFetchSet,
      dispatch,
      ...params,
      params: {
        sort: 'updated_at',
        page_size: '25',
      },
    })
  }

export const fetchFrontPageArticles =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleType>(`/frontpage/`, {
      dispatchPre: actions.preFetchSet,
      dispatchPost: actions.postFetchSet,
      dispatchFail: actions.failFetchSet,
      dispatch,
      method: 'GET',
      ...params,
    })
  }

export const fetchArticleContent =
  (articleId: string, { ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleExtType>(`/articles/${articleId}/`, {
      dispatchPre: actions.preFetchContent,
      dispatchPost: actions.postFetchContent,
      dispatchFail: actions.failFetchContent,
      dispatch,
      ...params,
    })
  }

export const fetchArticlesChunked =
  ({ ...chunkedParams }, { urlPrefix = '', ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<ArticleType>(
      `${urlPrefix}/articles/`,
      { ...chunkedParams },
      {
        dispatchPre: actions.preFetchSet,
        dispatchPost: actions.postFetchSet,
        dispatchFail: actions.failFetchSet,
        dispatch,
        ...params,
      }
    )
  }

export const fetchArticlesById = (articles: string[], { ...params } = {}) =>
  fetchArticlesChunked(
    {
      search: articles,
    },
    {
      method: 'POST',
      ...params,
    }
  )

export const fetchArticlesBySlug = (articles: string[], { body = {}, ...params } = {}) =>
  fetchArticlesById(articles, { ...params, body: { ...body, field: 'slug' } })

export const fetchMissingArticles =
  (articles: string[]) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const {
      article: { articles: existingArticles },
    } = getState()
    const articlesToFetch = [...articles].filter((key) => !(key in existingArticles))
    if (articlesToFetch.length < 1) return
    return await dispatch(fetchArticlesById(articlesToFetch))
  }

export const [useArticle, getArticle] = hookHelper<ArticleType, [id: string]>(
  createCachedSelector([articleState, passArgs], byId)(passArgs)
)
export const [useContent, getContent] = hookHelper<ArticleContent, [id: string]>(
  createCachedSelector([contentState, passArgs], byId)(passArgs)
)

const empty = {}

type ArticleExtNoContentGetter = (
  articles: Record<string, ArticleType>,
  categories: Record<string, CategoryType>,
  topics: Record<string, TopicType>,
  authors: Record<string, AuthorType>,
  id: string
) => ArticleExtType

type ArticleExtWithContentGetter = (
  articles: Record<string, ArticleWithContent>,
  categories: Record<string, CategoryType>,
  topics: Record<string, TopicType>,
  authors: Record<string, AuthorType>,
  id: string
) => ArticleExtWithContent

type ArticleExtGetter = ArticleExtNoContentGetter | ArticleExtWithContentGetter

const _getArticleExt: ArticleExtGetter = <T extends ArticleType | ArticleWithContent>(
  articles: Record<string, T>,
  categories: Record<string, CategoryType>,
  topics: Record<string, TopicType>,
  authors: Record<string, AuthorType>,
  id: string
) => {
  const article = articles[id]
  if (!article) return empty as any
  return {
    ...article,
    category: article.category
      ? stripPageData<CategoryType>(byId(categories, article.category))
      : null,
    author: article.author ? stripPageData<AuthorType>(byId(authors, article.author)) : null,
    topics: article.topics
      ? article.topics.map((t) => stripPageData(byId(topics, t))).filter((x) => !!x)
      : [],
  }
}

export const [useArticleExt, getArticleExt] = hookHelper<ArticleExtType, [id: string]>(
  createCachedSelector(
    [articleState, categoryState, topicState, authorState, passArgs],
    _getArticleExt as ArticleExtNoContentGetter
  )(passArgs)
)
export const [useContentExt, getContentExt] = hookHelper<ArticleExtWithContent, [id: string]>(
  createCachedSelector(
    [contentState, categoryState, topicState, authorState, passArgs],
    _getArticleExt as ArticleExtWithContentGetter
  )(passArgs)
)

// These don't need to be optimmized as they only fire once per page load.
export const [useArticleBySlug, getArticleBySlug] = hookHelper<
  ArticleType | undefined,
  [slug: string]
>(({ article: { articles } }, slug, category = null) =>
  Object.values<ArticleType>(articles).find(
    ({ slug: aSlug, category: catId }) =>
      (category === null || catId === category) && slug === aSlug
  )
)
export const [useContentBySlug, getContentBySlug] = hookHelper<
  ArticleWithContent | undefined,
  [slug: string]
>(({ article: { content } }, slug, category = null) =>
  Object.values<ArticleWithContent>(content).find(
    ({ slug: aSlug, category: catId }) =>
      (category === null || catId === category) && slug === aSlug
  )
)

const initialState = {
  articles: {},
  content: {},
}

export const mapDispatchToProps = {
  fetchArticles,
  fetchFrontPageArticles,
  fetchArticleContent,
  fetchArticlesChunked,
  fetchArticlesById,
  fetchArticlesBySlug,
  fetchMissingArticles,
}

export const reducer = reducerFromActions(actions, initialState)
