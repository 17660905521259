import React from 'react'
import CategoryType from 'store/types/ts/category'
import Typography from '../../../../typography'
import { CategoryLink } from '../../../../link'
import Image from 'components/image'
import style from '../style'
import { DefaultImageEnum } from 'util/default-image'

interface Props {
  item: CategoryType
  className?: string
}

const panelClasses = style.combine('col-12 col-md-6 col-lg-4', 'news-panel')

const Categories = ({ item, className = panelClasses }: Props): JSX.Element => {
  const { id, name, description, image } = item

  return (
    <div className={style.combine(className, 'category-panel', 'news-listing')}>
      <CategoryLink id={id} className={style('image')}>
        <div className={style('imgWrapper')}>
          <Image
            className={style('img')}
            images={[image]}
            defaultImageName={DefaultImageEnum.CategoryBanner}
            resizeProps={{
              w: 850, // Minimum width set to 850, could be set lower but look at mobile single column layout then.
              h: 478,
              fm: 'webp',
              fit: 'fill',
            }}
            alt=""
          />
        </div>
      </CategoryLink>
      {name && (
        <CategoryLink id={id} className={style('title')}>
          <Typography variant="h3">{name}</Typography>
        </CategoryLink>
      )}
      {description && (
        <Typography variant="body" className={style('desc')}>
          {description}
        </Typography>
      )}
    </div>
  )
}

export default Categories
