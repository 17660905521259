import PropTypes from 'prop-types'
import Image from './image'

const articleProps = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: Image,
  heroImage: Image,
  listingImage: Image,
  parent: PropTypes.string,
  publicationDate: PropTypes.string,
  updatedAt: PropTypes.string,
  author: PropTypes.string,
  category: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string),
}

export const Article = PropTypes.shape(articleProps)

export const Content = PropTypes.shape({
  id: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
})

export const ArticleWithContent = PropTypes.shape({
  ...articleProps,
  content: PropTypes.arrayOf(Content),
})
