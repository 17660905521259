import { withDispatch } from 'store'
import { useArticle } from 'store/article'
import { useRelatedResults } from 'store/related'
import { ResultsLayout, SidebarLayout, ListingLayout } from './layouts'

export const RelatedListing = ({ id, layout, ...props }) => {
  const sidebar = layout && layout === 'sidebar'

  let DefaultLayout = ResultsLayout
  let showPaging = true
  let results = useRelatedResults()

  if (sidebar) {
    DefaultLayout = SidebarLayout
    showPaging = false
    results = {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ...useRelatedResults(),
      articles: results.articles.slice(0, 3),
    }
  }

  const article = useArticle(id)

  return ListingLayout({
    ...article,
    ...results,
    DefaultLayout,
    type: 'related',
    pages: sidebar ? 0 : results.pages,
    id,
    showPaging,
    ...props,
  })
}
RelatedListing.getInitialProps = async ({
  id: article,
  layout,
  page = 1,
  fetchRelatedPage,
  fetchMissing,
}) => {
  const sidebar = layout && layout === 'sidebar'
  const DefaultLayout = sidebar ? SidebarLayout : ResultsLayout

  await ListingLayout.getInitialProps({
    fetch: fetchRelatedPage,
    fetchMissing,
    page,
    DefaultLayout,
    article,
  })
}
export default withDispatch({
  related: ['fetchRelatedPage'],
  shared: ['fetchMissing'],
})(RelatedListing)
