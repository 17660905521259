import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'

import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/category'
import * as propTypes from './types/category'
import { hookHelper } from 'util/store'
import {
  categoryState,
  passArgs,
  stripObjPageData,
  matchSlug,
  byId,
  uniqueList,
  validCategories,
} from './common'
import { Dispatch } from 'redux'
import CategoryType, { CategoryStats } from './types/ts/category'
import ArticleType from './types/ts/article'
const bindActions = actionBinder(actions)
export { propTypes }

export const fetchCategoriesIfNeeded =
  ({ ...params }) =>
  async (dispatch: Dispatch<any>, getState: () => any) => {
    const categories = categoryState(getState())
    if (Object.keys(categories).length > 0) return

    const actions = bindActions(dispatch)

    return await api.all<CategoryType>('/categories/', {
      dispatchPre: actions.preFetch,
      dispatchPost: actions.postFetch,
      dispatchFail: actions.failFetch,
      dispatch,
      ...params,
    })
  }

export const fetchCategoriesChunked =
  ({ ...chunkedParams }, { ...params }) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<CategoryType>(
      '/categories/',
      { ...chunkedParams },
      {
        dispatchPre: actions.preFetch,
        dispatchPost: actions.postFetch,
        dispatchFail: actions.failFetch,
        dispatch,
        ...params,
      }
    )
  }

export const fetchCategoriesById = (categories: string[], { ...params } = {}) =>
  fetchCategoriesChunked(
    {
      search: categories,
    },
    {
      method: 'POST',
      ...params,
    }
  )
export const fetchCategoriesBySlug = (
  categories: string[],
  { params: initialParams = {}, ...params } = {}
) =>
  fetchCategoriesById(categories, {
    ...params,
    params: { ...initialParams, field: 'slug' },
  })

interface fetchCategoryArticlePageProps extends Record<string, any> {
  category: string
  sort?: string
  order?: string
  page?: number
  page_size?: number
  year?: number
  month?: number
}

export const fetchCategoryArticlePage =
  ({
    category,
    sort,
    order,
    page = 1,
    page_size = 25,
    year,
    month,
    ...params
  }: fetchCategoryArticlePageProps) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleType>(`/categories/${category}/articles/`, {
      dispatchPre: actions.preFetchCategoryArticlePage,
      dispatchPost: actions.postFetchCategoryArticlePage,
      dispatchFail: actions.failFetchCategoryArticlePage,
      dispatch,
      ...params,
      params: {
        ...(params.params || {}),
        sort,
        order,
        page,
        page_size,
        year,
        month,
      },
      target: { category, page },
    })
  }

interface fetchCategoryArticlesProps {
  category: string
  articles: string[]
}

export const fetchCategoryArticlesById =
  ({ category, articles }: fetchCategoryArticlesProps, { ...params } = {}) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<ArticleType>(
      `/categories/${category}/articles/`,
      {
        search: articles,
      },
      {
        dispatchPre: actions.preFetchCategoryArticlePage,
        dispatchPost: actions.postFetchCategoryArticlePage,
        dispatchFail: actions.failFetchCategoryArticlePage,
        dispatch,
        method: 'POST',
        ...params,
        target: { category },
      }
    )
  }
export const fetchCategoryArticlesBySlug = (
  { category, articles }: fetchCategoryArticlesProps,
  { body = {}, ...params } = {}
) =>
  fetchCategoryArticlesById({ category, articles }, { ...params, body: { ...body, field: 'slug' } })

export const fetchCategoryStats =
  ({ category }: { category: string }, { ...params } = {}) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)

    return await api<CategoryStats>(`/categories/${category}/stats/`, {
      dispatchPre: actions.preFetchStats,
      dispatchPost: actions.postFetcHStats,
      dispatchFail: actions.failFetchStats,
      dispatch,
      ...params,
    })
  }

export const fetchMissingCategories =
  (categories: string[]) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const existingCategories = categoryState(getState())
    const categoriesToFetch = uniqueList(
      [...categories].filter((key) => !(key in existingCategories))
    )
    if (categoriesToFetch.length < 1) return
    return await dispatch(fetchCategoriesById(categoriesToFetch))
  }

export const [useCategory, getCategory] = hookHelper<CategoryType, [id: string]>(
  createCachedSelector([categoryState, passArgs], byId)(passArgs)
)
export const [useCategories, getCategories] = hookHelper<Record<string, CategoryType>, never[]>(
  createSelector(categoryState, (category) => stripObjPageData(validCategories(category)))
)
export const [useCategoryBySlug, getCategoryBySlug] = hookHelper<
  CategoryType | undefined,
  [slug: string]
>(createCachedSelector([categoryState, passArgs], matchSlug)(passArgs))
export const [useCategoryStats, getCategoryStats] = hookHelper<CategoryStats, never[]>(
  ({ category: { stats } }) => stats
)

const initialState = {}

export const mapDispatchToProps = {
  fetchCategoriesById,
  fetchCategoriesBySlug,
  fetchCategoriesChunked,
  fetchCategoriesIfNeeded,
  fetchCategoryArticlePage,
  fetchCategoryArticlesById,
  fetchCategoryArticlesBySlug,
  fetchCategoryStats,
  fetchMissingCategories,
}

export const reducer = reducerFromActions(actions, initialState)
