import { actionReducer, extractHeaders } from 'util/api'
import { pageData } from '../common'
import { createLogger } from 'util/log'

const logger = createLogger('TopicAPI')

// Update state for topic pages
const updateTopic = (state, { topic, ...data }) => ({
  ...state,
  [topic]: {
    ...pageData,
    ...(state[topic] || {}),
    ...data,
  },
})
const updateTopicList = (state, topics) =>
  topics.reduce((state, topic) => updateTopic(state, { topic: topic.id, ...topic }), state)
export const preFetchTopicArticlePage = actionReducer(
  'TOPIC_FETCH_ARTICLE_PAGE_START',
  ({ topic, page = 0 }) => ({ topic, page }),
  (state, { topic, page }) => updateTopic(state, { topic, page, status: 'Loading' })
)
export const postFetchTopicArticlePage = actionReducer(
  'TOPIC_FETCH_ARTICLE_PAGE_END',
  (res, items, { topic }) => ({ status: res.status, items, topic, ...extractHeaders(res) }),
  (state, { topic, page, pages, total, results, items = [] }) => {
    logger.log(`Received page ${page}/${pages} for ${topic}`)
    return updateTopic(state, {
      topic,
      articles: (items || []).map((item) => item.id),
      page,
      pages,
      total,
      results,
      status: 'Loaded',
    })
  }
)
export const failFetchTopicArticlePage = actionReducer(
  'TOPIC_FETCH_ARTICLE_PAGE_FAIL',
  ({ status }, { topic, page }) => ({ status, topic, page }),
  (state, { topic, page }) => updateTopic(state, { topic, page, status: 'Failed' })
)

export const preFetch = actionReducer('TOPIC_FETCH_START')
export const failFetch = actionReducer('TOPIC_FETCH_FAIL', ({ status }) => ({ status }))
export const postFetch = actionReducer(
  'TOPIC_FETCH_DONE',
  ({ status }, page) => ({ status, page }),
  (state, { page }) => updateTopicList(state, page)
)
