import React from 'react'
import PropTypes from 'prop-types'
import { withOverlay } from '../../util/overlay'

// import style from './style'
import SearchBar from './searchbar'
import { SearchListing } from '../layout/listing'

const SearchOverlay = ({ close }) => {
  return (
    <>
      {/* <h1 className={style('overlay-header')}>Search</h1> */}
      <SearchBar />
      <SearchListing onItemClick={close} />
    </>
  )
}
SearchOverlay.propTypes = {
  close: PropTypes.func,
}

export default withOverlay('search', SearchOverlay)
