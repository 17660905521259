import { actionReducer, extractHeaders } from 'util/api'
import { pageData } from '../common'
import { createLogger } from 'util/log'

const logger = createLogger('AuthorAPI')

// Update state for author pages
const updateAuthor = (state, { author, ...data }) => ({
  ...state,
  [author]: {
    ...pageData,
    ...(state[author] || {}),
    ...data,
  },
})
const updateAuthorList = (state, authors) =>
  authors.reduce((state, author) => updateAuthor(state, { author: author.id, ...author }), state)
export const preFetchAuthorArticlePage = actionReducer(
  'AUTHOR_FETCH_ARTICLE_PAGE_START',
  ({ author, page = 0 }) => ({ author, page }),
  (state, { author, page }) => updateAuthor(state, { author, page, status: 'Loading' })
)
export const postFetchAuthorArticlePage = actionReducer(
  'AUTHOR_FETCH_ARTICLE_PAGE_END',
  (res, items, { author }) => ({ status: res.status, items, author, ...extractHeaders(res) }),
  (state, { author, page, pages, total, results, items = [] }) => {
    logger.log(`Received page ${page}/${pages} for ${author}`)
    return updateAuthor(state, {
      author,
      articles: (items || []).map((item) => item.id),
      page,
      pages,
      total,
      results,
      status: 'Loaded',
    })
  }
)
export const failFetchAuthorArticlePage = actionReducer(
  'AUTHOR_FETCH_ARTICLE_PAGE_FAIL',
  ({ status }, { author, page }) => ({ status, author, page }),
  (state, { author, page }) => updateAuthor(state, { author, page, status: 'Failed' })
)

export const preFetch = actionReducer('AUTHOR_FETCH_START')
export const postFetch = actionReducer(
  'AUTHOR_FETCH_END',
  ({ status }, page) => ({ status, page }),
  (state, { page }) => updateAuthorList(state, page)
)
export const failFetch = actionReducer('AUTHOR_FETCH_FAIL')
export const startFetch = actionReducer('AUTHOR_FETCH_START')
export const doneFetch = actionReducer('AUTHOR_FETCH_DONE')
