import { ResultsLayout, ListingLayout } from './layouts'
import { withDispatch } from 'store'
import { useSearchResults } from 'store/search'

const DefaultLayout = ResultsLayout

export const SearchListing = ({ ...props }) => {
  const results = useSearchResults()
  return ListingLayout({ ...results, DefaultLayout, type: 'search', ...props })
}
SearchListing.getInitialProps = async ({
  query,
  author,
  topic,
  category,
  page = 1,
  fetchSearchPage,
  fetchMissing,
}) => {
  await ListingLayout.getInitialProps({
    fetch: fetchSearchPage,
    fetchMissing,
    page,
    DefaultLayout,
    query,
    author,
    topic,
    category,
  })
}
export default withDispatch({
  search: ['fetchSearchPage'],
  shared: ['fetchMissing'],
})(SearchListing)
