import React, { useState, useCallback, useEffect } from 'react'
import Select from 'react-select-me'
import 'react-select-me/lib/ReactSelectMe.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'

import debounce from 'lodash.debounce'
import { withDispatch } from 'store'
import { useCategories } from 'store/category'
import { useOverlay } from 'store/overlay'
import { Search } from '../icons'

import { logSearch } from '../../util/analytics'

import style from './style'

export const SearchBar = ({
  fetchSearchPage,
  fetchMissing,
  setOverlayData,
  initialSearch = '',
}) => {
  const { data: { query: stateQuery = '', category: stateCategory = undefined } = {} } =
    useOverlay('search')

  const [[category, query], setSearchData] = useState(() => [stateCategory, stateQuery])

  const setQuery = (query) => {
    setOverlayData('search', { query })
    setSearchData((state) => [state[0], query])
  }
  const setCategory = (category) => {
    setOverlayData('search', { category })
    setSearchData((state) => [category, state[1]])
  }

  // If we've been given an initial search (i.e. during the /search page), we save the query
  // for pagination.
  useEffect(() => {
    if (initialSearch !== '') {
      setQuery(initialSearch)
    }
    // TODO: Rework with useEffect hooks?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSearch])

  const doSearch = async ({ query, category }) => {
    NProgress.start()
    const [res, items] = await fetchSearchPage({ query, category }) // eslint-disable-line no-unused-vars
    NProgress.inc()
    await fetchMissing(items)
    NProgress.done()
  }

  // TODO: Rework this to be more hook-friendly?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(
      ({ query, category }) => {
        if (!query || query.length < 2) return
        if (category === '') category = null
        doSearch({ query, category })
        logSearch({ query, category })
      },
      500,
      { maxWait: 5000 }
    ),
    []
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    debouncedUpdate({ query, category })
  }
  const handleSearch = (e) => {
    setQuery(e.target.value)
    debouncedUpdate({ query: e.target.value, category })
  }
  const handleCategory = (e) => {
    setCategory(e.value)
    debouncedUpdate({ query, category: e.value })
  }

  // Change keys for select compatibility
  const categories = Object.values(useCategories()).map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  // Add 'All Categories' option
  const categoriesOptions = [
    {
      value: '',
      label: 'All Categories',
    },
    ...categories,
  ]

  const iconRenderer = () => {
    return <FontAwesomeIcon icon={faAngleDown} />
  }

  // react-select-me classnames
  const dropdownClassnames = {
    dd__wrapper: style('dd__wrapper'),
    dd__selectControl: style('dd__selectControl'),
    dd__list: style('dd__list'),
    dd__placeholder: style('dd__placeholder'),
    dd__selectedItem: style('dd__selectedItem'),
    dd__selected: style('dd__selected'),
    dd__option: style('dd__option'),
    dd__selectedOption: style('dd__selectedOption'),
  }

  return (
    <>
      <div className={style('search')}>
        <div className={style('search-bar')}>
          <Search className={style('search-icon')} />
          <form onSubmit={handleSubmit}>
            <input
              className={style('search-input')}
              type="text"
              value={query}
              ref={(input) => input && input.focus()}
              placeholder="Type to search..."
              onChange={handleSearch}
            />
          </form>
        </div>
        <div className={style('divider')} />
        <Select
          options={categoriesOptions}
          value={category || categoriesOptions[0]}
          onChange={handleCategory}
          iconRenderer={iconRenderer}
          s={dropdownClassnames}
        />
      </div>
      {/* <div className={style('filter', `${!category ? 'all' : ''}`)}>
        {categoriesOptions.map((cat) => (
          <div
            key={cat.value}
            onClick={() => handleCategory(cat)}
            className={style('filter-item', `${cat.value === category ? 'active' : ''}`)}
          >
            {cat.label}
          </div>
        ))}
      </div> */}
    </>
  )
}
SearchBar.propTypes = {
  fetchMissing: PropTypes.func,
  fetchSearchPage: PropTypes.func,
  setOverlayData: PropTypes.func,
  initialSearch: PropTypes.string,
}
export default withDispatch({
  search: ['fetchSearchPage'],
  shared: ['fetchMissing'],
  overlay: ['setOverlayData'],
})(SearchBar)
