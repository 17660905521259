import React from 'react'

import getConfig from 'next/config'
const {
  publicRuntimeConfig: { errorBanner },
} = getConfig()

import Head from 'components/head'
import ContentContainer from 'components/container'
import { withDispatch } from 'store'

interface Props {
  error: number
  setHero: Function
}

const ErrorPage = ({ error, setHero }: Props) => {
  setHero({
    image: errorBanner,
    heading: error,
    subHeading: 'Whoops',
  })
  return (
    <>
      <Head>
        <title>{error}</title>
      </Head>
      <ContentContainer>
        <h1>{error}</h1>
      </ContentContainer>
    </>
  )
}

export default withDispatch({
  hero: ['setHero'],
})(ErrorPage)
