import { NewsLayoutAuthor, ListingLayout } from './layouts'
import { getAuthor, useAuthor } from 'store/author'
import { withDispatch } from 'store'
import { getPageData } from 'store/common'

const DefaultLayout = NewsLayoutAuthor

export const AuthorListing = ({ id, ...props }) => {
  const author = useAuthor(id)
  return ListingLayout({
    ...author,
    ...getPageData(author),
    DefaultLayout,
    type: 'author',
    id,
    ...props,
  })
}
AuthorListing.getInitialProps = async ({
  store,
  id: author,
  page = 1,
  fetchAuthorArticlePage,
  fetchMissing,
}) => {
  const { layout } = getAuthor(store, author)
  await ListingLayout.getInitialProps({
    fetch: fetchAuthorArticlePage,
    fetchMissing,
    DefaultLayout,
    page,
    layout,
    author,
  })
}
export default withDispatch({
  author: ['fetchAuthorArticlePage'],
  shared: ['fetchMissing'],
})(AuthorListing)
