import toDate from 'date-fns-tz/toDate'
import mockData from './mock.json'
import { getLink } from '../link'
import { YCDate } from '../../util/datetime'
import { useCategoryStats } from 'store/category'
import { enUS } from 'date-fns/locale'
import { useMemo } from 'react'
import style from './date-filter.module.scss'
import { HasPeriodStats } from 'store/types/ts/category'

interface Props {
  type: string
  id: string
  year: number
  month: number
}

const DateFilter = ({ type, id, year, month: activeMonth }: Props) => {
  const Link = getLink(type)

  const stats = useCategoryStats()

  const monthEntries: Record<string, Record<string, number>> = useMemo(() => {
    if (stats.year['*'] === null) return {}
    const entries = Object.entries<number>(stats.month as HasPeriodStats)
    return entries.reduce((obj, [key, val]) => {
      const dt = toDate(key, { timeZone: 'Etc/UTC' })
      const year = '' + dt.getUTCFullYear()
      const month = '' + (dt.getUTCMonth() + 1) // Javascript months are 0-indexed...

      return {
        ...obj,
        [year]: {
          ...(obj[year] || {}),
          [month]: val,
        },
      }
    }, {} as Record<string, Record<string, number>>)
  }, [stats.month, stats.year])

  // Ensure that if there are no stats, we don't try to render
  if (stats.year['*'] === null) return <></>

  const yearEntries = Object.entries(stats.year) as [string | null, number][]

  const months = Array.from({ length: 12 }, (_, i) => i).map((i) => [
    i + 1,
    enUS.localize?.month(i, { width: 'abbreviated' }),
  ])

  const renderData = () => {
    return yearEntries
      .reverse()
      .filter(([key, val]) => val !== 0)
      .map(([key, val]) => {
        const parsed = key === null ? null : toDate(key, { timeZone: 'Etc/UTC' })
        const formatYear = parsed === null ? null : parsed.getUTCFullYear()
        const renderDate = key ? <YCDate date={key} ycFormat="yyy" dateFormat="yyyy" /> : formatYear

        return (
          <Link
            key={formatYear}
            id={id}
            query={{ year: '' + formatYear }}
            className={`${style.item} ${year === formatYear ? style.active : ''}`}
          >
            {renderDate} <span className={style.count}>({val})</span>
          </Link>
        )
      })
  }
  const renderMonths = () => {
    return months.map(([month, val]) => (
      <Link
        key={`month-${month}`}
        id={id}
        query={{ year, month }}
        className={`${style.item} ${month === activeMonth ? style.active : ''}`}
      >
        {val} <span className={style.count}>({monthEntries[year][month] || 0})</span>
      </Link>
    ))
  }

  return (
    <aside className={style.filter}>
      <div className={style.title}>Filter</div>
      <div className={style.overflow}>
        <div className={style.years}>
          {renderData()}
          {!year ? (
            ''
          ) : (
            <>
              <Link key="show-all" id={id} query={{}} className={style.item}>
                <span>Show All</span>
              </Link>
            </>
          )}
        </div>
        {year ? <div className={style.months}>{renderMonths()}</div> : <></>}
      </div>
    </aside>
  )
}

export default DateFilter
