import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { getDefaultImage, DefaultImageEnum } from 'util/default-image'
import Head from 'components/head'
import ContentContainer, { Row, HomePanel } from 'components/container'
import { withDispatch } from 'store'

import style from './404.module.scss'

const error404Banner = getDefaultImage(DefaultImageEnum.NotFoundBanner)

interface Props {
  setHero: Function
}

const Error404 = ({ setHero }: Props) => {
  setHero({
    image: error404Banner,
    heading: '404 - Page Not Found',
    subHeading: 'M-404',
  })

  return (
    <div className={style.error}>
      <Head>
        <title>Page Not Found</title>
      </Head>
      <ContentContainer>
        <Row>
          <HomePanel>
            <p>
              You have entered system M-404. The destination you were looking for has moved, been
              destroyed or does not exist. One innocent navigation misclick can lead to interstellar
              warfare and mass destruction, please be more careful in the future!
            </p>
            <p>
              It’s all fun and games until{' '}
              <a href="https://www.eveonline.com/news/view/a-weekend-of-epic-destruction-in-eve-online">
                someone loses a Titan
              </a>
              .
            </p>
            <ul>
              <li>
                <FontAwesomeIcon className={style.arrow} icon={faChevronRight} />{' '}
                <a href="https://www.eveonline.com/">Home</a>
              </li>
            </ul>
          </HomePanel>
        </Row>
      </ContentContainer>
    </div>
  )
}

export default withDispatch({
  hero: ['setHero'],
})(Error404)
