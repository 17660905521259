import React from 'react'
import style from './style'

export const SimpleElement =
  (baseClass, Element = 'div') =>
  // eslint-disable-next-line react/display-name, react/prop-types
  ({ className = '', children, ...props }) =>
    (
      <Element className={style.combine(baseClass, className)} {...props}>
        {children}
      </Element>
    )

export const ContentContainer = SimpleElement(style('container', 'content-container'))
export const Container = SimpleElement(style('container'))
export const ArticleContainer = SimpleElement(style('article-container'))
export const Row = SimpleElement(style('row'))
export const Col = SimpleElement(style('col'))
export const LandingPagePanel = SimpleElement(style('col', 'landing-page-column'))
export const HomePanel = SimpleElement(style('col', 'content-column'))
export const MainPanel = SimpleElement(
  style('col', 'order-1', 'order-lg-1', 'col-12', 'col-lg-8', 'pr-lg-4', 'content-column')
)
export const SidePanel = SimpleElement(
  style('col', 'order-2', 'order-lg-2', 'col-12', 'col-lg-4', 'pl-lg-4', 'sidebar-column')
)

export default ContentContainer
