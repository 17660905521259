import Image from 'components/image'
import ArticleType from 'store/types/ts/article'
import { ArticleLink } from 'components/link'

import style from '../style'
interface Props {
  item: ArticleType
  className?: string
}

const panelClasses = style.combine('col-6  col-lg-3', 'news-panel')

const Empires = ({ item, className = panelClasses }: Props): JSX.Element => {
  const { id, slug } = item

  const logo = `/images/logos/${slug}-logo.png`

  return (
    <div className={style.combine(className, 'empires-panel', 'news-listing')}>
      <ArticleLink id={id} className={style('inner')}>
        {item.image && item.image.resizable && (
          <div className={style('imgWrapper')}>
            <Image
              image={`/images/empires/${slug}-frontpage.jpg`}
              objectFit="cover"
              resizeProps={{
                w: 377,
                h: 377,
                fit: 'fill',
              }}
              alt=""
            />
            <div className={style('logo')}>
              <Image image={logo} resizeProps={{ w: 250, h: 250 }} objectFit="scale-down" alt="" />
            </div>
          </div>
        )}
      </ArticleLink>
    </div>
  )
}

export default Empires
