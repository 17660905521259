import { createSelector } from 'reselect'

import getConfig from 'next/config'
const {
  publicRuntimeConfig: {
    defaultArticleBanner,
    defaultArticleDetailView,
    defaultCategoryBanner,
    defaultAuthorBanner,
    defaultTopicBanner,
  },
} = getConfig()

import { actionReducer, actionBinder, reducerFromActions } from '../util/api'
import { getArticleExt } from './article'
import { getCategory } from './category'
import { getAuthor } from './author'
import { getTopic } from './topic'
import { hookHelper } from 'util/store'
import { getImage } from 'util/image'

import { heroState } from './common'
import { Dispatch } from 'redux'
import CategoryType from './types/ts/category'
import HeroType from './types/ts/hero'

const heroReduce = actionReducer<HeroType, [HeroType], HeroType>(
  'UPDATE_HERO',
  ({ image, heading, subHeading, description, align, articleId, overlay }) => ({
    image,
    heading,
    subHeading,
    description,
    align,
    articleId,
    overlay,
  }),
  (state, { image, heading, subHeading, description, align, articleId, overlay }) => ({
    ...state,
    image,
    heading,
    subHeading,
    description,
    align,
    articleId,
    overlay,
  })
)

const actions = {
  heroReduce,
}
const bindActions = actionBinder(actions)

export const setArticleHero =
  (id: string, { ...override } = {}) =>
  (dispatch: Dispatch<any>, getState: () => any) => {
    const { heroReduce } = bindActions(dispatch)
    const { title, category } = getArticleExt({ getState }, id)
    const { image: catImage, heroImage: catHeroImage } = category || ({} as CategoryType)
    const image = getImage([catHeroImage, catImage], defaultArticleDetailView)

    heroReduce({
      image,
      heading: title,
      articleId: id,
      overlay: true,
      ...override,
    })
  }
export const setCategoryHero =
  (id: string, { ...override } = {}) =>
  (dispatch: Dispatch<any>, getState: () => any) => {
    const { heroReduce } = bindActions(dispatch)
    const { image: catImage, name, description } = getCategory({ getState }, id)
    const image = getImage([catImage], defaultCategoryBanner)

    heroReduce({
      image,
      heading: name,
      description,
      overlay: true,
      ...override,
    })
  }
export const setTopicHero =
  (id: string, { ...override } = {}) =>
  (dispatch: Dispatch<any>, getState: () => any) => {
    const { heroReduce } = bindActions(dispatch)
    const { image: topicImage, name } = getTopic({ getState }, id)
    const image = getImage([topicImage], defaultTopicBanner)

    heroReduce({
      image,
      heading: name,
      // subHeading: name,
      ...override,
    })
  }
export const setAuthorHero =
  (id: string, { ...override } = {}) =>
  (dispatch: Dispatch<any>, getState: () => any) => {
    const { heroReduce } = bindActions(dispatch)
    const { image: authorImage, name } = getAuthor({ getState }, id)
    const image = getImage([authorImage], defaultAuthorBanner)

    heroReduce({
      image,
      heading: name,
      ...override,
    })
  }

export const setHero =
  ({ image, heading, subHeading, description, align, articleId, overlay }: HeroType) =>
  (dispatch: Dispatch<any>) =>
    bindActions(dispatch).heroReduce({
      image,
      heading,
      subHeading,
      description,
      align,
      articleId,
      overlay,
    })

export const mapDispatchToProps = {
  setArticleHero,
  setAuthorHero,
  setCategoryHero,
  setTopicHero,
  setHero,
}

const initialState = {
  image: undefined,
  heading: undefined,
  subHeading: undefined,
  description: undefined,
  articleId: undefined,
  publicationDate: undefined,
  category: undefined,
  overlay: undefined,
}

export const [useHero, getHero] = hookHelper<HeroType>(
  createSelector(
    heroState,
    ({ image, heading, subHeading, description, align, articleId, overlay }) => ({
      image,
      heading,
      subHeading,
      description,
      align,
      articleId,
      overlay,
    })
  )
)

export const reducer = reducerFromActions(actions, initialState)
