import React from 'react'
import PropTypes from 'prop-types'
import _truncate from 'lodash/truncate'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { isCorpOrFaction } from 'util/helpers'
import Typography from 'components/typography'
import Image from 'components/image'
import { Article } from 'store/types/article'

import { ArticleLink } from '../../../link'
import style from './style'

import { useArticleExt } from 'store/article'
import { DefaultImageEnum } from 'util/default-image'

const panelClasses = style.combine('col-12 col-lg-6 col-xl-4', 'default-panel')

export const defaultPropTypes = {
  item: Article,
  id: PropTypes.string,
  className: PropTypes.string,
  layout: PropTypes.string,
  onItemClick: PropTypes.func,
}

export const DefaultLayout = ({ item, id, className = panelClasses, layout, onItemClick }) => {
  const {
    category,
    title,
    description,
    image: articleImage,
    listingImage,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = item || useArticleExt(id)

  let categoryID = category
  if (!item) {
    categoryID = category.id
  }

  const categoryName = useSelector((state) => state.category[categoryID].name)
  const categoryImage = useSelector((state) => state.category[categoryID].image)

  const renderImage = () => {
    let resizeProps = {
      w: 850,
      h: 478,
      fm: 'webp',
      fit: 'fill',
    }

    if (isCorpOrFaction(categoryID))
      resizeProps = {
        w: 250,
        h: 250,
      }

    return (
      <Image
        className={style('img')}
        images={[listingImage, articleImage, category.image || categoryImage]}
        defaultImageName={DefaultImageEnum.ArticleBanner}
        resizeProps={resizeProps}
        alt=""
      />
    )
  }

  return (
    <div className={classnames(className, `${layout}-panel`)}>
      <ArticleLink id={id} onClick={onItemClick} className={style('card')}>
        <div className={style('imgWrapper')}>{renderImage()}</div>
        <div id={id} onClick={onItemClick} className={style('content')}>
          <span className={style('category')}>{categoryName}</span>
          <Typography variant="h3" className={style('title')}>
            {title}
          </Typography>
          <Typography variant="body" className={style('desc')}>
            {_truncate(description, {
              length: 90,
              omission: '[...]',
            })}
          </Typography>
        </div>
      </ArticleLink>
    </div>
  )
}
DefaultLayout.propTypes = defaultPropTypes

export default DefaultLayout
