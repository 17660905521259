import PropTypes from 'prop-types'
import Image from './image'

export const Topic = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: Image,
  layout: PropTypes.string.isRequired,
  description: PropTypes.string,
  landingPage: PropTypes.string,
})
