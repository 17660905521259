import { actionReducer, extractHeaders } from 'util/api'
import { pageData } from '../common'
import { createLogger } from 'util/log'

const logger = createLogger('CategoryAPI')

// Update state for category pages
const updateCategory = (state, { category, ...data }) => ({
  ...state,
  [category]: {
    ...pageData,
    ...(state[category] || {}),
    ...data,
  },
})
const updateCategoryList = (state, categories) =>
  categories.reduce(
    (state, category) => updateCategory(state, { category: category.id, ...category }),
    state
  )
export const preFetchCategoryArticlePage = actionReducer(
  'CATEGORY_FETCH_ARTICLE_PAGE_START',
  ({ category, page = 0 }) => ({ category, page }),
  (state, { category, page }) => updateCategory(state, { category, page, status: 'Loading' })
)
export const postFetchCategoryArticlePage = actionReducer(
  'CATEGORY_FETCH_ARTICLE_PAGE_END',
  (res, items, { category }) => ({ status: res.status, items, category, ...extractHeaders(res) }),
  (state, { category, page, pages, total, results, items = [] }) => {
    logger.log(`Received page ${page}/${pages} for ${category}`)
    return updateCategory(state, {
      category,
      articles: (items || []).map((item) => item.id),
      page,
      pages,
      total,
      results,
      status: 'Loaded',
    })
  }
)
export const failFetchCategoryArticlePage = actionReducer(
  'CATEGORY_FETCH_ARTICLE_PAGE_FAIL',
  ({ status }, { category, page }) => ({ status, category, page }),
  (state, { category, page }) => updateCategory(state, { category, page, status: 'Failed' })
)

export const preFetch = actionReducer('CATEGORY_FETCH_START')
export const failFetch = actionReducer('CATEGORY_FETCH_FAIL', ({ status }) => ({ status }))
export const postFetch = actionReducer(
  'CATEGORY_FETCH_DONE',
  ({ status }, page) => ({ status, page }),
  (state, { page }) => updateCategoryList(state, page)
)

export const preFetchStats = actionReducer('CATEGORY_FETCH_STATS_START')
export const failFetchStats = actionReducer('CATEGORY_FETCH_STATS_FAIL', ({ status }) => ({
  status,
}))
export const postFetcHStats = actionReducer(
  'CATEGORY_FETCH_STATS_DONE',
  ({ status }, stats) => ({ status, stats }),
  (state, { stats }) => ({
    ...state,
    stats,
  })
)
