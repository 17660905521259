import { actionReducer, extractHeaders } from 'util/api'
import { pageData } from '../common'
import { createLogger } from 'util/log'

const logger = createLogger('RelatedAPI')

const updateRelated = (state, { ...data }) => ({
  ...pageData,
  ...state,
  ...data,
})
export const preFetchRelatedPage = actionReducer(
  'RELATED_FETCH_ARTICLE_PAGE_START',
  ({ article, page }) => ({ article, page }),
  (state, { article, page }) => updateRelated(state, { article, page, status: 'Loading' })
)
export const postFetchRelatedPage = actionReducer(
  'RELATED_FETCH_ARTICLE_PAGE_END',
  (res, items, { article }) => ({ status: res.status, article, items, ...extractHeaders(res) }),
  (state, { article, page, pages, total, results, items = [] }) => {
    logger.log(`Received page ${page}/${pages} for related article ${article}`)
    return updateRelated(state, {
      article,
      articles: (items || []).map((item) => item.id),
      page,
      pages,
      total,
      results,
      status: 'Loaded',
    })
  }
)
export const failFetchRelatedPage = actionReducer(
  'RELATED_FETCH_ARTICLE_PAGE_FAIL',
  ({ status }, { query, category, author, topic, page }) => ({
    status,
    query,
    category,
    author,
    topic,
    page,
  }),
  (state, { query, category, author, topic, page }) =>
    updateRelated(state, { query, category, author, topic, page, status: 'Failed' })
)
