import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AuthorLink, CategoryLink } from '../../components/link'
import { YCDate } from '../../util/datetime'
import { useArticleExt } from 'store/article'
import { useHero } from 'store/hero'
import style from './style'
import Head from 'components/head'
import Typography from 'components/typography'
import { Container } from 'components/container'

const defaultImage = '/assets/images/hero-background.jpg'

export const Hero = ({ Component = 'section', className = null, children }) => {
  const { image, heading, subHeading, description, align, articleId, overlay } = useHero() || {}

  const article = useArticleExt(articleId || '')

  const published = useMemo(
    () =>
      articleId && article?.publicationDate ? (
        <>
          {' | '}
          <YCDate date={article.publicationDate} />
        </>
      ) : (
        ''
      ),
    [article, articleId]
  )
  const author = useMemo(
    () =>
      articleId && article?.author ? (
        <>
          {' - '} By <AuthorLink id={article.author.id} analyticsAction="hero-link" />
        </>
      ) : (
        ''
      ),
    [article, articleId]
  )

  const classNames = cx(className, style('hero'), {
    [style('hero-article')]: articleId,
    [style('hero-overlay')]: overlay,
  })

  return (
    <Component className={classNames} style={image ? { backgroundImage: `url(${image})` } : {}}>
      <Head>
        <meta property="og:image" content={image || defaultImage} />
        <meta name="twitter:image" content={image || defaultImage} />
      </Head>
      {children || (
        <Container className={style('container', `${align}`)}>
          <Typography
            variant="h1"
            className={article && article.publicationDate ? style('articeDetail') : ''}
            uppercase
          >
            {heading}
          </Typography>
          <div className={style('dateAuth')}>
            {article && article.category && (
              <CategoryLink id={article.category.id} analyticsAction="sidebar-link" />
            )}
            {published}
            {author}
          </div>
          {subHeading && (
            <Typography uppercase variant="tagline">
              {subHeading}
            </Typography>
          )}
          {description && (
            <div className="row">
              <div className="col-12 col-md-9">
                <p className={style('desc')}>{description}</p>
              </div>
            </div>
          )}
        </Container>
      )}
    </Component>
  )
}
Hero.propTypes = {
  Component: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Hero
