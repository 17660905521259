import React from 'react'
import NProgress, { NProgressOptions } from 'nprogress'
import Router from 'next/router'

interface NavigationProps extends NProgressOptions {
  stopDelayMs?: number
}

/**
 * A Component to trigger NProgress on next.js router changes.
 *
 * Note: The style for NProgress is done in _index.scss
 */
class NavigationProgress extends React.Component<NavigationProps> {
  static defaultProps = {
    stopDelayMs: 100,
  }

  timer?: NodeJS.Timeout

  routeChangeStart = () => {
    NProgress.start()
  }

  routeChangeEnd = () => {
    if (this.timer) clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      NProgress.done(true)
    }, this.props.stopDelayMs)
  }

  render() {
    return <></>
  }

  componentDidMount() {
    const { stopDelayMs, ...options } = this.props as NavigationProps
    if (options) {
      NProgress.configure(options as NProgressOptions)
    }

    Router.events.on('routeChangeStart', this.routeChangeStart)
    Router.events.on('routeChangeComplete', this.routeChangeEnd)
    Router.events.on('routeChangeError', this.routeChangeEnd)
  }
  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.routeChangeStart)
    Router.events.off('routeChangeComplete', this.routeChangeEnd)
    Router.events.off('routeChangeError', this.routeChangeEnd)
  }
}

export default NavigationProgress
