import { createSelector } from 'reselect'
import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/related'
import { hookHelper } from 'util/store'
import { relatedState, pageData, getPageData, pageDataType } from './common'
import { Dispatch } from 'redux'
import ArticleType from './types/ts/article'
const bindActions = actionBinder(actions)

interface fetchRelatedPageProps {
  article: string
  page?: number
  page_size?: number
  params?: Record<string, any>
}

export const fetchRelatedPage =
  ({ article, page = 1, page_size = 25, ...params }: fetchRelatedPageProps) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    return await api<ArticleType>(`/articles/${article}/related/`, {
      dispatchPre: actions.preFetchRelatedPage,
      dispatchPost: actions.postFetchRelatedPage,
      dispatchFail: actions.failFetchRelatedPage,
      dispatch,
      ...params,
      params: {
        ...(params.params || {}),
        page,
        page_size,
      },
      method: 'GET',
      target: { article, page },
    })
  }

const initialState = {
  article: undefined,
  ...pageData,
}

export const [useRelated, getRelated] = hookHelper<string>(
  createSelector(relatedState, ({ article }) => article)
)
export const [useRelatedResults, getRelatedResults] = hookHelper<pageDataType>(
  createSelector(relatedState, getPageData)
)

export const mapDispatchToProps = {
  fetchRelatedPage,
}

export const reducer = reducerFromActions(actions, initialState)
