import React from 'react'
import cx from 'classnames'
import style from './link-arrow.module.scss'

interface ArrowProps {
  children: React.ReactNode
  reverse?: boolean
}

interface LinkProps extends ArrowProps {
  className?: string
}

const Arrow = ({ children, reverse }: ArrowProps): JSX.Element => (
  <div className={cx(style.inner, { [style.reverse]: reverse })}>
    <div
      className={cx(style.arrow, style.left, {
        [style.reverse]: reverse,
      })}
    >
      <div className={style.shaft} />
    </div>
    <div className={style.main}>
      <span className={style.text}>{children}</span>
      <div className={cx(style.arrow, style.right)}>
        <div className={style.shaft} />
      </div>
    </div>
  </div>
)

const LinkArrow = ({ children, reverse = false, className }: LinkProps): JSX.Element => {
  return (
    <div className={cx(style.link, className)}>
      <Arrow reverse={reverse}>{children}</Arrow>
    </div>
  )
}

export default LinkArrow
