import { useEffect, useState, useRef } from 'react'

function useSticky() {
  const [isSticky, setSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 250 ? setSticky(true) : setSticky(false)
    }

    // Scroll performance
    const debounce = (func, wait = 0, immediate = true) => {
      let timeOut
      return () => {
        let context = this,
          args = arguments
        const later = () => {
          timeOut = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeOut
        clearTimeout(timeOut)
        timeOut = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    }

    if (window !== undefined) {
      window.addEventListener('scroll', debounce(handleScroll))
    }

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  })

  return { isSticky }
}

export default useSticky
