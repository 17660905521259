import React from 'react'
import PropTypes from 'prop-types'

import style from './style'
import { getLink } from '../link'

export const Paginator = (props) => {
  const { type, id, page, pages, neighbours = 2, query = {} } = props
  const linkClass = style('page-link')

  const Link = getLink(type)

  const arrow = (page, title, alt) => {
    const active = page >= 1 && page <= pages
    return (
      <li
        key={`arrow-${page}-${alt}`}
        className={style('page-item', !active ? 'disabled' : 'null')}
      >
        {active ? (
          <Link id={id} query={query} page={page} title={title} className={style('page-link', alt)}>
            {title}
          </Link>
        ) : (
          <a href="#" className={style('page-link', alt)}>
            {title}
          </a>
        )}
      </li>
    )
  }

  const left = 1,
    right = pages,
    pageLeft = page - neighbours,
    pageRight = page + neighbours
  const leftWide = neighbours * 2 + 1,
    rightWide = pages - neighbours * 2
  let allPages = [],
    dotted = false

  const pageTest = (i) =>
    i <= left || // Page 1
    i >= right || // Page N
    (i >= pageLeft && i <= pageRight) || // Space around current page
    (page <= neighbours && i <= leftWide) || // Page at the start, show more neighbours
    (page >= pages - neighbours && i >= rightWide) // Page at the end, show more neighbours

  for (let i = 1; i <= pages; i++) {
    // Test if this page should be shown.
    // If not, check if the previous page was valid, and the next page would also be valid.
    // This is to avoid showing [...] between 2 pages (i.e. [1] [2] [3] instead of [1] [...] [3]).
    if (pageTest(i) || (!dotted && pageTest(i + 1))) {
      allPages.push(
        <li key={`${id}-page-${i}`} className={style('page-item', i === page ? 'active' : null)}>
          <Link id={id} page={i} query={query} title={i} className={linkClass}>
            {i}
          </Link>
        </li>
      )
      dotted = false
    } else if (!dotted) {
      dotted = true
      allPages.push(
        <li key={`${id}-page-dot-${i}`} className={style('page-item', 'disabled')}>
          <a className={linkClass} href="#" tabIndex="-1">
            &hellip;
          </a>
        </li>
      )
    }
  }
  if (pages <= 1) {
    return <></>
  }
  return (
    <ul className={style('pagination', pages >= 5 ? 'can-expand' : 'can-wide')}>
      {arrow(page - 1, 'Previous', 'prev')}
      {allPages}
      {arrow(page + 1, 'Next', 'next')}
    </ul>
  )
}
Paginator.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  neighbours: PropTypes.number,
}

export default Paginator
