import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'

import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/topic'
import * as propTypes from './types/topic'
import { hookHelper } from 'util/store'
import { uniqueList, topicState, passArgs, stripObjPageData, matchSlug, byId } from './common'
import TopicType from './types/ts/topic'
import { AnyAction, Dispatch } from 'redux'
import ArticleType from './types/ts/article'
const bindActions = actionBinder(actions)
export { propTypes }

export const fetchTopicsChunked =
  ({ ...chunkedParams }, { ...params }) =>
  async (dispatch: Dispatch) => {
    const actions = bindActions(dispatch)
    return await api.chunked<TopicType>(
      '/topics/',
      { ...chunkedParams },
      {
        dispatchPre: actions.preFetch,
        dispatchPost: actions.postFetch,
        dispatchFail: actions.failFetch,
        dispatch,
        ...params,
      }
    )
  }

export const fetchTopicsById = (topics: string[], { ...params } = {}) =>
  fetchTopicsChunked(
    {
      search: topics,
    },
    {
      method: 'POST',
      ...params,
    }
  )
export const fetchTopicsBySlug = (topics: string[], { params: innerParams = {}, ...params } = {}) =>
  fetchTopicsById(topics, { ...params, params: { ...innerParams, field: 'slug' } })

interface fetchTopicArticlePageProps {
  topic: string
  sort: string
  order: 'asc' | 'desc' | 'auto'
  page?: number
  page_size?: number
  params?: Record<string, any>
}
export const fetchTopicArticlePage =
  ({ topic, sort, order, page = 1, page_size = 25, ...params }: fetchTopicArticlePageProps) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const actions = bindActions(dispatch)
    const { params: innerParams = {} } = params
    return await api<ArticleType>(`/topics/${topic}/articles/`, {
      dispatchPre: actions.preFetchTopicArticlePage,
      dispatchPost: actions.postFetchTopicArticlePage,
      dispatchFail: actions.failFetchTopicArticlePage,
      dispatch,
      ...params,
      params: {
        ...innerParams,
        sort,
        order,
        page,
        page_size,
      },
      target: { topic, page },
    })
  }

interface fetchTopicArticlesByIdProps {
  topic: string
  articles: string[]
}
export const fetchTopicArticlesById =
  ({ topic, articles }: fetchTopicArticlesByIdProps, { ...params } = {}) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const actions = bindActions(dispatch)
    return await api.chunked<ArticleType>(
      `/topics/${topic}/articles/`,
      {
        search: articles,
      },
      {
        dispatchPre: actions.preFetchTopicArticlePage,
        dispatchPost: actions.postFetchTopicArticlePage,
        dispatchFail: actions.failFetchTopicArticlePage,
        dispatch,
        method: 'POST',
        ...params,
      }
    )
  }
export const fetchTopicArticlesBySlug = (
  { topic, articles }: fetchTopicArticlesByIdProps,
  { body = {}, ...params } = {}
) => fetchTopicArticlesById({ topic, articles }, { ...params, body: { ...body, field: 'slug' } })

export const fetchMissingTopics =
  (topics: string[]) => async (dispatch: Dispatch<any>, getState: () => any) => {
    const { topic } = getState()
    const topicsToFetch = uniqueList([...topics].filter((key) => !(key in topic)))
    if (topicsToFetch.length < 1) return
    return await dispatch(fetchTopicsById(topicsToFetch))
  }

export const [useTopic, getTopic] = hookHelper<TopicType, [id: string]>(
  createCachedSelector([topicState, passArgs], byId)(passArgs)
)
export const [useTopics, getTopics] = hookHelper<Record<string, TopicType>, never>(
  createSelector(topicState, (topic) => stripObjPageData(topic))
)
export const [useTopicBySlug, getTopicBySlug] = hookHelper<TopicType | undefined, [slug: string]>(
  createCachedSelector([topicState, passArgs], matchSlug)(passArgs)
)

const initialState = {}

export const mapDispatchToProps = {
  fetchTopicsById,
  fetchTopicsBySlug,
  fetchTopicsChunked,
  fetchTopicArticlesById,
  fetchTopicArticlesBySlug,
  fetchTopicArticlePage,
  fetchMissingTopics,
}

export const reducer = reducerFromActions(actions, initialState)
