import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Link from 'next/link'
import { useRouter } from 'next/router'

import { CategoryLink } from '../../link'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useMenus } from 'store/menu'
import { useCategory } from 'store/category'
import { SearchMenu } from '../../search'
import { Logo } from '../../icons'
import useSticky from './useSticky'

import style from './style'

const MenuItem = ({ id }) => {
  const { name } = useCategory(id)
  return (
    <CategoryLink
      analyticsAction="menu-link"
      id={id}
      passHref
      wrapChildren={false}
      className={style('nav-link')}
    >
      <NavDropdown.Item className={style('dropdown-item')}>{name}</NavDropdown.Item>
    </CategoryLink>
  )
}
MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
}

const MenuBrand = () => (
  <Link href="/" passHref>
    <Navbar.Brand className={style('brand')}>
      <Logo />
      <span className="sr-only">Home</span>
    </Navbar.Brand>
  </Link>
)

const MenuDropdown = ({ item }) => {
  const { categories, name } = item
  return (
    <NavDropdown title={name} className={style('dropdown')}>
      {categories.map((id) => (
        <MenuItem key={id} id={id} />
      ))}
    </NavDropdown>
  )
}
MenuDropdown.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
  }),
}

export const Menu = () => {
  const [expanded, setExpanded] = useState(false)

  const menuList = Object.entries(useMenus())
    .filter(([key]) => key !== 'state')
    .map(([, val]) => val)
    .sort((a, b) => a.sortOrder - b.sortOrder)
  const { asPath } = useRouter()
  const [activeKey] = asPath.split('?')

  const { isSticky } = useSticky()

  return (
    <div className={cx(style('navigation'), { [style('sticky')]: isSticky })}>
      <Navbar
        className={style('navbar')}
        collapseOnSelect
        expand="lg"
        variant="dark"
        sticky="top"
        onToggle={setExpanded}
        expanded={expanded}
      >
        <MenuBrand />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className={style('toggler')}>
          <span />
          <span />
          <span />
        </Navbar.Toggle>
        <Navbar.Collapse className={style('navbar-collapse')} id="top-menu">
          <Nav className="mr-auto" activeKey={activeKey}>
            {menuList.map((item) => (
              <MenuDropdown item={item} key={item.id} className={style('test2')} />
            ))}
          </Nav>
          <Nav className="ml-auto my-2 my-lg-0">
            <SearchMenu closeMenu={setExpanded} />
          </Nav>
        </Navbar.Collapse>
        <div className={style('navbar-right-anchor')} />
      </Navbar>
    </div>
  )
}

export default Menu
