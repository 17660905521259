import { actionReducer, listToDict } from 'util/api'

export const preFetch = actionReducer('MENU_FETCH_PAGE_START')
export const postFetch = actionReducer(
  'MENU_FETCH_PAGE_DONE',
  ({ status }, page) => ({ status, page }),
  (state, { page }) => ({ ...state, ...listToDict(page), state: 'loaded' })
)
export const failFetch = actionReducer(
  'MENU_FETCH_PAGE_FAILED',
  ({ status }, page) => ({ status, page }),
  (state) => ({ ...state, state: 'failed' })
)
export const startFetch = actionReducer(
  'MENU_FETCH_MENU_START',
  () => ({}),
  (state) => ({ ...state, state: 'loading' })
)
export const doneFetch = actionReducer(
  'MENU_FETCH_MENU_DONE',
  ({ status }, menu, pageCount) => ({ status, menu, pageCount }),
  (state, { menu }) => ({ ...state, ...listToDict(menu), state: 'loaded' })
)
