import Image, { ImageProps } from 'next/image'

import { getImage } from 'util/image'
import { getDefaultImage, DefaultImageEnum } from 'util/default-image'
import ImageType, { ImageParamsType } from 'store/types/ts/image'

// import style from './image.module.scss'

interface Props {
  image?: string
  images?: Array<ImageType | undefined>
  defaultImageName?: DefaultImageEnum
  resizeProps?: ImageParamsType
  objectFit?: 'cover' | 'scale-down' | undefined
  className?: string
  alt: string
}

const ImageContainer = ({
  image,
  images,
  defaultImageName,
  resizeProps,
  objectFit,
  className,
  alt,
}: Props) => {
  const defaultImage = getDefaultImage(defaultImageName)

  // We can either pass in one image url as string or array of image types
  // Bit long but this just uses image, or if images array is not null then calls getImage with that, else img url is set to null and nothing is rendered out
  const img = image ? image : images ? getImage([...images], defaultImage, resizeProps) : null

  if (img) {
    let imageProps: ImageProps = {
      src: img,
      layout: 'fill',
      objectFit, // this uses original size
      objectPosition: 'bottom center', // TODO, maybe we need to add this as prop
    }

    // Props for next/image
    // Either widht and height has to be set, or layout=fill

    if (resizeProps) {
      const width = resizeProps.w
      const height = resizeProps.h
      if (width && height) {
        imageProps = {
          src: img,
          width,
          height,
          objectFit, // this uses original size
        }
      }
    }

    return <Image className={className} {...imageProps} alt={alt} />
  }

  return null
}

export default ImageContainer
