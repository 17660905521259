import { actionReducer, extractHeaders } from 'util/api'
import { pageData } from '../common'
import { createLogger } from 'util/log'

const logger = createLogger('SearchAPI')

const updateSearch = (state, { ...data }) => ({
  ...pageData,
  ...state,
  ...data,
})
export const preFetchSearchPage = actionReducer(
  'SEARCH_FETCH_ARTICLE_PAGE_START',
  ({ category, page = 0 }) => ({ category, page }),
  (state, { query, category, author, topic, page }) =>
    updateSearch(state, { query, category, author, topic, page, status: 'Loading' })
)
export const postFetchSearchPage = actionReducer(
  'SEARCH_FETCH_ARTICLE_PAGE_END',
  (res, items, { query, category, author, topic }) => ({
    status: res.status,
    items,
    query,
    category,
    author,
    topic,
    ...extractHeaders(res),
  }),
  (state, { query, category, author, topic, page, pages, total, results, items = [] }) => {
    logger.log(`Received page ${page}/${pages} for query '${query}'`)
    return updateSearch(state, {
      query,
      category,
      author,
      topic,
      articles: (items || []).map((item) => item.id),
      page,
      pages,
      total,
      results,
      status: 'Loaded',
    })
  }
)
export const failFetchSearchPage = actionReducer(
  'SEARCH_FETCH_ARTICLE_PAGE_FAIL',
  ({ status }, { query, category, author, topic, page }) => ({
    status,
    query,
    category,
    author,
    topic,
    page,
  }),
  (state, { query, category, author, topic, page }) =>
    updateSearch(state, { query, category, author, topic, page, status: 'Failed' })
)
