interface Props {
  className?: string
}

const SearchIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
  >
    <path
      fill="#29353A"
      d="M14.796 12.969l-2.921-2.921a.703.703 0 00-.498-.205h-.478A6.093 6.093 0 006.094 0a6.093 6.093 0 100 12.186c1.415 0 2.716-.48 3.75-1.289v.478c0 .187.074.366.206.498l2.92 2.92a.7.7 0 00.994 0l.83-.829a.706.706 0 00.002-.995zM6.094 9.843a3.748 3.748 0 01-3.75-3.75 3.748 3.748 0 013.75-3.75 3.748 3.748 0 013.75 3.75 3.748 3.748 0 01-3.75 3.75z"
    />
  </svg>
)

export default SearchIcon
