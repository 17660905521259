import { FunctionComponent } from 'react'
import { Helmet, HelmetProps } from 'react-helmet'

// Look into replacing Helmet with next/head if we want to simplify
// import Head from 'next/head'

/**
 * Wrapper component for react-helmet
 * @param props Same props as react-helmet
 * @returns Children rendered inside html head element
 */
const Head: FunctionComponent<HelmetProps> = (props) => {
  // return <Head>{children}</Head>

  return <Helmet {...props}>{props.children}</Helmet>
}

export default Head
