import ArticleType from 'store/types/ts/article'

import { DefaultLayout } from 'components/layout/listing/layouts'
import style from '../style'

interface Props {
  articles: ArticleType[]
}

const Layout = ({ articles }: Props): JSX.Element => {
  // Must be at least 3 articles
  let article1, article2, article3
  if (articles && articles.length > 2) {
    // Todo we don't want to show description here so removing it before display, find  better way of doing that
    article1 = articles[0]
    // article1.description = ''
    article2 = articles[1]
    article2.description = ''
    article3 = articles[2]
    article3.description = ''
  }

  return (
    <>
      {article1 && (
        <DefaultLayout
          key={article1.id}
          id={article1.id}
          item={article1}
          className={style('col-12 col-md-8', 'top-panel', 'featured')}
        />
      )}
      {article2 && (
        <div className={style('col-12 col-md-4', 'featured-2')}>
          <DefaultLayout
            key={article2.id}
            id={article2.id}
            item={article2}
            className={style('top-panel')}
          />
          {article3 && (
            <DefaultLayout
              key={article3.id}
              id={article3.id}
              item={article3}
              className={style('top-panel')}
            />
          )}
        </div>
      )}
    </>
  )
}

export default Layout
