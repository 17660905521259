// import moduleStyle from "./util.module.scss";

const StyleHelper = (style) => {
  const helper = (...names) =>
    names
      .filter((name) => !!name)
      .reduce((obj, name) => [...obj, name, style[name]], [])
      .filter((name) => !!name)
      .join(' ')
  helper.combine = (left, ...names) => [left, helper(...names)].join(' ').trim()
  helper.extend = (left, right) => [left, right].join(' ').trim()
  helper.extendClassName = (Component, ...names) => {
    const extendComponent = (props) =>
      Component({ ...props, className: helper.combine(props.className, ...names) })
    extendComponent.displayName = Component.displayName || Component.name
    extendComponent.propTypes = Component.propTypes || {}
    extendComponent.defaultProps = Component.defaultProps || {}
    return extendComponent
  }
  return helper
}

// export const style = StyleHelper(moduleStyle);
export default StyleHelper
