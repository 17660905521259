import { ComponentType, useMemo } from 'react'
import * as redux from 'react-redux'
import { createStore, combineReducers, bindActionCreators, applyMiddleware, Dispatch } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import thunk from 'redux-thunk'

export { bindActionCreators }

import { reduceHelper, mappingProps } from 'util/store'

import * as author from './author'
import * as article from './article'
import * as category from './category'
import * as menu from './menu'
import * as topic from './topic'
import * as shared from './shared'
import * as hero from './hero'
import * as search from './search'
import * as related from './related'
import * as overlay from './overlay'
import { Store } from './types/ts/store'
import { NextPageContext } from 'next'

// All our stores
const stores = {
  author: author as unknown as Store<typeof author>,
  article: article as unknown as Store<typeof article>,
  category: category as unknown as Store<typeof category>,
  menu: menu as unknown as Store<typeof menu>,
  topic: topic as unknown as Store<typeof topic>,
  shared: shared as unknown as Store<typeof shared>,
  hero: hero as unknown as Store<typeof hero>,
  search: search as unknown as Store<typeof search>,
  related: related as unknown as Store<typeof related>,
  overlay: overlay as unknown as Store<typeof overlay>,
}
// For better typing, we pre-map all these.
const AllMapDispatchToProps = {
  author: author.mapDispatchToProps,
  article: article.mapDispatchToProps,
  category: category.mapDispatchToProps,
  menu: menu.mapDispatchToProps,
  topic: topic.mapDispatchToProps,
  shared: shared.mapDispatchToProps,
  hero: hero.mapDispatchToProps,
  search: search.mapDispatchToProps,
  related: related.mapDispatchToProps,
  overlay: overlay.mapDispatchToProps,
}

const reducer = reduceHelper(combineReducers(mappingProps(stores, 'reducer')))

export const propTypes = mappingProps(stores, 'propTypes')

type WithDispatchKeys<T = typeof AllMapDispatchToProps> = {
  [K in keyof T]?: [...keys: (keyof T[K])[]]
}
interface WithDispatchProps extends WithDispatchKeys {}

type DispatchType = <T extends ComponentType<any>>(
  mapping: WithDispatchProps
) => (component: T) => T

export const withDispatch: DispatchType = ({ ...mapping }) => {
  const entries = Object.entries(mapping as Record<string, string[]>)
  const map = AllMapDispatchToProps as Record<string, Record<string, any>>

  // Map all dispatch functions properly, each key in the mapping object
  //  will result in that module's dispatch functions to be mapped
  //  to the component properties.
  const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    const actionCreators = entries.reduce(
      (obj, [key, names]) => ({
        ...obj,
        ...Object.fromEntries(names.map((name) => [name, map[key][name]])),
      }),
      {}
    )
    return bindActionCreators(actionCreators, dispatch)
  }

  return function (klass: any) {
    const returned = redux.connect(null, mapDispatchToProps)(klass)
    if (returned.getInitialProps) {
      const getInitialProps = returned.getInitialProps.bind(returned)
      returned.getInitialProps = async function (ctx: NextPageContext) {
        const { store } = ctx
        return await getInitialProps({ ...ctx, ...mapDispatchToProps(store.dispatch) })
      }
    }
    return returned
  }
}

type ConnectKeys<T = typeof stores> = {
  [K in keyof T]?: [...keys: (keyof T[K])[]]
}
interface ConnectKeysProps extends ConnectKeys {
  extra?: (state: any, props: any) => {}
}
interface ConnectUseProps {
  useState?: boolean
  useDispatch?: boolean
  useFunctions?: boolean
}

/**
 * Connects a component to the redux state.
 * @param {Object} mapping The mapping to apply, each key should exist in {@link stores}, with the value being the state mapper function.
 */
export function connect(
  { extra = undefined, ...mapping }: ConnectKeysProps,
  { useState = true, useDispatch = true }: ConnectUseProps = {}
) {
  const map = AllMapDispatchToProps as Record<string, Record<string, any>>
  const keys = Object.keys(mapping)
  const entries = Object.entries(mapping)

  // Map all states properly, each state item in the mapping object
  //  will map the sub-state of that module (and as such, the mapping)
  //  function will directly get the state of that module, rather than
  //  the global state
  const mapStateToProps = (state: any, props: any) => {
    const base = extra ? { ...extra(state, props) } : {}
    return entries.reduce(
      (obj, [key, method]) => ({
        ...obj,
        ...method(state[key], props),
      }),
      base
    )
  }
  // Map all dispatch functions properly, each key in the mapping object
  //  will result in that module's dispatch functions to be mapped
  //  to the component properties.
  const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return keys.reduce(
      (obj, key) => ({
        ...obj,
        ...bindActionCreators(map[key] || {}, dispatch),
      }),
      {}
    )
  }
  // Wrapper so that we can intercept getInitialProps for this component (if it exists).
  // This allows us to send the right state and dispatch functions along with the context
  //  so that you have access to the same functionality as with this.props
  return function (klass: any) {
    const returned = redux.connect(
      useState ? mapStateToProps : null,
      useDispatch ? mapDispatchToProps : null
    )(klass)
    returned.BaseComponent = klass
    if (returned.getInitialProps) {
      const getInitialProps = returned.getInitialProps.bind(returned)
      returned.getInitialProps = async function (ctx: NextPageContext) {
        const { store } = ctx
        const state = store.getState()
        const stateProps = useState ? mapStateToProps(state, ctx) : {}
        const dispatchProps = useDispatch ? mapDispatchToProps(store.dispatch) : {}
        const newCtx = { ...ctx, ...stateProps, ...dispatchProps }
        return await getInitialProps(newCtx)
      }
    }
    return returned
  }
}

type ActionKeys<T = typeof stores> = [...keys: (keyof T)[]]

export function useActions(...actions: ActionKeys) {
  const dispatch = redux.useDispatch()
  return useMemo(() => {
    const map = AllMapDispatchToProps as Record<string, Record<string, any>>
    return actions.reduce(
      (obj, name) => ({
        ...obj,
        ...bindActionCreators(map[name], dispatch),
      }),
      {}
    )
  }, [dispatch, actions])
}

export const makeStore = () => createStore(reducer, applyMiddleware(thunk))

export const wrapper = createWrapper(makeStore)
export default connect
