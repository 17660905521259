type IntInputType = number | string | null | undefined

/**
 * Converts a value to a number. If it is not a number, a default is returned
 * @param x The value to convert
 * @param _default The default value to return if it is not a number
 */
export const toInt = (x: IntInputType, _default: number = NaN): number => {
  if (x === null || x === undefined) return _default
  if (typeof x === 'number') return x
  try {
    const num = Number(x)
    return isNaN(num) ? _default : num
  } catch {
    return _default
  }
}

/**
 * Splits a list of items into chunks of a specific size.
 *
 * @param input The list of items to split up
 * @param size The maximum size a chunk may have.
 * @returns A list of chunks of items.
 */
export function toChunks<P = any>(input: P[], size: number): P[][] {
  return Array(Math.ceil(input.length / size))
    .fill(null)
    .map((_, i) => input.slice(i * size, i * size + size))
}
