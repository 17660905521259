import React from 'react'
import PropTypes from 'prop-types'

import Link from 'next/link'

import { Search } from '../icons'

import style from './style'
import { withDispatch } from 'store'

export const SearchMenu = ({ closeMenu = () => {}, showOverlay }) => {
  const show = () => showOverlay('search')

  return (
    <>
      <Link href="/search" as="/search">
        <a
          className={style('nav-link', 'search-overlay-link')}
          onClick={(e) => {
            e.preventDefault()
            show()
            closeMenu(false)
          }}
        >
          <div className={style('search-icon')}>
            <Search />
          </div>
        </a>
      </Link>
    </>
  )
}
SearchMenu.propTypes = {
  closeMenu: PropTypes.func,
  showOverlay: PropTypes.func,
}

export default withDispatch({
  overlay: ['showOverlay'],
})(SearchMenu)
