import formatISO from 'date-fns/formatISO'
import format from 'date-fns-tz/format'
import toDate from 'date-fns-tz/toDate'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import addYears from 'date-fns/addYears'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const getYCDate = (dt: Date) => addYears(dt, -1898)
export const formatYCDate = (dt: Date, fmt = 'yyy-MM-dd') =>
  `YC${format(getYCDate(dt), fmt, { timeZone: 'UTC' })}`
export const formatDate = (dt: Date, fmt = 'yyyy-MM-dd') => format(dt, fmt, { timeZone: 'UTC' })

export const toISO = (dt: string) => formatISO(toDate(dt))

interface YCDateProps {
  date: string
  ycFormat?: string
  dateFormat?: string
}

/**
 * Displays a date in EVE's YC format.
 * When the user hovers over the YC-formatted date, a tooltip will
 * be shown with the out-of-game representation of that date.
 *
 * @param param.date The date to display, an ISO string
 */
export const YCDate = ({ date, ycFormat, dateFormat }: YCDateProps) => {
  const parsed = utcToZonedTime(toDate(date, { timeZone: 'Etc/UTC' }), 'Etc/UTC')

  return (
    <>
      <OverlayTrigger
        key="ycdate"
        placement="top"
        overlay={<Tooltip id={`tooltip-${date}`}>{formatDate(parsed, dateFormat)}</Tooltip>}
      >
        <span>{formatYCDate(parsed, ycFormat)}</span>
      </OverlayTrigger>
    </>
  )
}
