import React from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'

import { withDispatch } from 'store'
import { useArticleExt } from 'store/article'

import { event } from 'util/analytics'

const analyticsCategory = 'related'

export const RelatedLink = ({
  id,
  page = 1,
  children,
  analyticsAction,
  showOverlay,
  fetchRelatedPage,
  fetchMissing,
  ...props
}) => {
  const show = (id) => showOverlay('related', id)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const article = id ? useArticleExt(id) : null

  const doSearch = async () => {
    NProgress.start()
    const [res, items] = await fetchRelatedPage({ article: id, page }) // eslint-disable-line no-unused-vars
    NProgress.inc()
    await fetchMissing(items)
    NProgress.done()
  }
  const analyticsEvent = () => {
    if (!analyticsAction || !article || !article.id) return
    event({
      category: analyticsCategory,
      action: analyticsAction,
      label: `/${article.category.slug}/${article.slug}`,
    })
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        doSearch()
        e.preventDefault()
        show(id)
        analyticsEvent()
      }}
      {...props}
    >
      {children || 'Related items'}
    </a>
  )
}
RelatedLink.propTypes = {
  id: PropTypes.string.isRequired,
  page: PropTypes.number,
  children: PropTypes.node,
  analyticsAction: PropTypes.string,
  showOverlay: PropTypes.func,
  fetchRelatedPage: PropTypes.func,
  fetchMissing: PropTypes.func,
}

export default withDispatch({
  overlay: ['showOverlay'],
  related: ['fetchRelatedPage'],
  shared: ['fetchMissing'],
})(RelatedLink)
