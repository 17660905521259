import { createSelector } from 'reselect'

import { api, actionBinder, reducerFromActions } from '../util/api'

import * as actions from './actions/search'
import { hookHelper } from 'util/store'
import { searchState, pageData, getPageData, pageDataType } from './common'
import ArticleType from './types/ts/article'
import { Dispatch } from 'redux'
import { SearchQueryType } from './types/ts/search'
const bindActions = actionBinder(actions)

interface fetchSearchPageProps extends SearchQueryType {
  query: string
  page?: number
  page_size?: number
  body?: Record<string, any>
}

export const fetchSearchPage =
  ({ query, category, topic, author, page = 1, page_size = 25, ...params }: fetchSearchPageProps) =>
  async (dispatch: Dispatch<any>) => {
    const actions = bindActions(dispatch)
    const { body = {} } = params
    return await api<ArticleType>(`/search/`, {
      dispatchPre: actions.preFetchSearchPage,
      dispatchPost: actions.postFetchSearchPage,
      dispatchFail: actions.failFetchSearchPage,
      dispatch,
      ...params,
      body: {
        ...body,
        query,
        category,
        topic,
        author,
        page,
        page_size,
      },
      method: 'POST',
      target: { query, category, topic, author, page },
    })
  }

const initialState = {
  query: null,
  category: undefined,
  topic: undefined,
  author: undefined,
  ...pageData,
}

export const [useSearch, getSearch] = hookHelper<SearchQueryType>(
  createSelector(searchState, ({ query, category, topic, author }: SearchQueryType) => ({
    query,
    category,
    topic,
    author,
  }))
)
export const [useSearchResults, getSearchResults] = hookHelper<pageDataType>(
  createSelector(searchState, getPageData)
)

export const mapDispatchToProps = {
  fetchSearchPage,
}

export const reducer = reducerFromActions(actions, initialState)
