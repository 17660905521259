import BaseLink from './base'
import { requireAtLeastOne } from '../../util/props'
import { useAuthor } from 'store/author'

const href = '/author/[author]'
const analyticsCategory = 'author'

export const AuthorLink = ({ id, slug = null, ...props }) => {
  let as = '',
    valid = true,
    title = slug || id
  if (!id && !!slug) {
    as = `/author/${slug}`
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const author = useAuthor(id)
    valid = !!author.id
    as = valid && `/author/${author.slug}`
    title = valid && (author.name || id)
  }

  return BaseLink({
    analyticsCategory,
    valid,
    href,
    as,
    title,
    ...props,
  })
}
AuthorLink.propTypes = {
  id: requireAtLeastOne('id', 'slug'),
  slug: requireAtLeastOne('id', 'slug'),
}

export default AuthorLink
