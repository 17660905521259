import { parse } from 'url'

import ArticleLink from './article'
import AuthorLink from './author'
import CategoryLink from './category'
import TopicLink from './topic'
import RelatedLink from './related'
import SearchLink from './search'

export { ArticleLink, AuthorLink, CategoryLink, TopicLink, RelatedLink, SearchLink }
const linkTypes = {
  article: ArticleLink,
  author: AuthorLink,
  category: CategoryLink,
  topic: TopicLink,
  related: RelatedLink,
  search: SearchLink,
}

export const getLink = (name, def = ArticleLink) => linkTypes[name] || def

export const detectLink = (href) => {
  const url = parse(href)
  if (url.pathname === null)
    return {
      type: 'hash',
      Component: 'a',
      id: href,
      props: { href: href },
    }

  if (!url.hostname && !url.protocol) {
    const topicMatch = url.pathname.match(/\/(@|topic)\/(.*)/)
    if (topicMatch)
      return {
        type: 'topic',
        Component: TopicLink,
        id: topicMatch[2],
        props: { slug: topicMatch[2] },
      }
    const authorMatch = url.pathname.match(/\/~|author\/(.*)/)
    if (authorMatch)
      return {
        type: 'author',
        Component: AuthorLink,
        id: authorMatch[2],
        props: { slug: authorMatch[2] },
      }
    if (!url.pathname.includes('/'))
      return {
        type: 'article',
        Component: ArticleLink,
        id: url.pathname,
        props: {
          id: url.pathname,
          hash: url.hash,
          search: url.search,
        },
      }
  }
  return {
    type: 'absolute',
    Component: 'a',
    id: href,
    props: { href: href },
  }
}
