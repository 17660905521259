import React from 'react'
import PropTypes from 'prop-types'
import { withOverlay } from '../../util/overlay'

import { RelatedListing } from '../layout/listing'
import Typography from 'components/typography'
import { useArticle } from 'store/article'

import style from './style'

const RelatedOverlay = ({ data, close }) => {
  const article = useArticle(data)
  return (
    <>
      <Typography variant="h2" className={style('overlay-header')}>
        Related: <span>{article.title}</span>
      </Typography>
      <RelatedListing onItemClick={close} id={data} />
    </>
  )
}
RelatedOverlay.propTypes = {
  data: PropTypes.string,
  close: PropTypes.func,
}

export default withOverlay('related', RelatedOverlay)
